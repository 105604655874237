import React from "react";
import {
  BiGridAlt,
  BiUserPin,
  BiTrashAlt,
  BiDownload,
  BiListPlus,
  BiListUl,
  BiEdit,
  BiUpload,
} from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import axios from "../api";
import fileDownload from "js-file-download";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";

const formatterPeso = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumFractionDigits: 2,
});

const download = (url) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, url.substr(url?.lastIndexOf("=") + 1));
    });
};

const downloadDocuments = (props) => (
  <BiDownload
    size="25px"
    onClick={() => download(props.ruta)}
    style={{ cursor: "pointer" }}
  />
);

const deleteDocuments = (props) => (
  <BiTrashAlt
    size="25px"
    onClick={() => props.deleteDocuments(props.id)}
    style={{ cursor: "pointer" }}
  />
);

const state = (props) => (
  <div
    className={`p-1 my-5 rounded text-white border${props.estado == 1
      ? " bg-secondary border-secondary"
      : props.estado > 1 && props.estado < 4
        ? " bg-primary border-primary"
        : " bg-success border-success"
      }`}
    onClick={() =>
      props.permission.includes(2) &&
        props.permissionSupervisor.includes(2) &&
        props.estado < 4
        ? props.state(props)
        : props.permissionSupervisor.includes(2) && props.estado === 3
          ? props.state(props)
          : props.permission.includes(2) && props.estado < 3
            ? props.state(props)
            : null
    }
    style={{
      cursor:
        props.permission.includes(2) &&
          props.permissionSupervisor.includes(2) &&
          props.estado < 4
          ? "pointer"
          : props.permissionSupervisor.includes(2) && props.estado === 3
            ? "pointer"
            : props.permission.includes(2) && props.estado < 3
              ? "pointer"
              : "default",
    }}
  >
    <span className="h6">
      {props.estado == 1
        ? "Pendiente"
        : props.estado == 2
          ? "En curso"
          : props.estado == 3
            ? `¿Aprobar?`
            : "Finalizado"}
    </span>
  </div>
);
const stateActivity = (props) => (
  <div
    className={`p-1 my-5 rounded text-white border${props.vp_estado_actividad == 1
      ? " bg-secondary border-secondary"
      : props.vp_estado_actividad > 1 && props.vp_estado_actividad < 4
        ? " bg-primary border-primary"
        : " bg-success border-success"
      }`}
  >
    <span className="h6">
      {props.vp_estado_actividad == 1
        ? "Pendiente"
        : props.vp_estado_actividad == 2
          ? "En curso"
          : props.vp_estado_actividad == 3
            ? `por Aprobar`
            : "Finalizado"}
    </span>
  </div>
);

const deleteIndicators = (props) => (
  <BiTrashAlt
    size="25px"
    onClick={() => props.daleteIndicador(props.id)}
    style={{ cursor: "pointer" }}
  />
);

const porcentaje = (props) => <p>{`${props.dedicacion}%`}</p>;
const memberName = (props) => (
  <p>{`${props.miembros.nombres} ${props.miembros.apellidos}`}</p>
);
const valorpresupuesto = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p>{formatterPeso.format(props.valorpresupuesto)}</p>
  </div>

);
const projectPresupuesto = (props) => (
  <p>{formatterPeso.format(props.presupuesto)}</p>
);
const formatDate = (date) => {
  return date === null ? "" : moment(date).format("L");
};

const valorreal = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p>{formatterPeso.format(props.valorreal)}</p>
  </div>
);
const startDate = (props) => <p>{formatDate(props.fecha_inicio)}</p>;
const endDate = (props) => <p>{formatDate(props.fecha_fin)}</p>;
const saldo = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p>{formatterPeso.format(props.valorpresupuesto - props.valorreal)}</p>
  </div>
);
const rubro = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p >{props.itemcategoria.categorias.nombre}</p>
  </div>
);
const concepto = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p>{props.itemcategoria.nombre}</p>
  </div>
);
const financiado = (props) => (
  <div style={{ backgroundColor: props.valorpresupuesto - props.valorreal != 0 ? 'yellow' : 'white' }}>
    <p>{props.vp_financiamiento}</p>
  </div>
);

export const activityAccion = (props) => (
  <div className="flex">
    <BiDownload
      title="Descargar Archivo"
      size="25px"
      onClick={props.download}
      style={{ cursor: "pointer", display: props.ruta !== "" ? null : "none" }}
      className="mx"
    />
    <BiUpload
      title="Cargar Archivo"
      size="25px"
      onClick={props.upload}
      style={{
        cursor: "pointer",
        display:
          props.groupId === 5 ||
            props.groupId === 6 ||
            (props.groupId === 7 && props.ruta === "")
            ? null
            : "none",
      }}
      className="mx"
    />
    <BiListPlus
      title="Agregar Indicadores"
      size="25px"
      onClick={props.addIndicador}
      style={{
        cursor: "pointer",
        display: props.permission.includes(2) ? null : "none",
      }}
      className="mx"
    />
    <BiListUl
      title="Lista Indicadores"
      size="25px"
      onClick={props.listIndicador}
      style={{
        cursor: "pointer",
        display: props.permission.includes(1) ? null : "none",
      }}
      className="mx"
    />
    <BiEdit
      title="Editar Entregable"
      size="25px"
      onClick={props.editentregable}
      style={{
        cursor: "pointer",
        display: props.permission.includes(3) ? null : "none",
      }}
      className="mx"
    />
    <MdDeleteOutline
      title="Eliminar Entregable"
      size="25px"
      onClick={props.deleteEntregable}
      style={{
        cursor: "pointer",
        display: props.permission.includes(4) ? null : "none",
      }}
      className="mx"
    />
  </div>
);

const bar = (props) => {
  return (
    <div
      className={`p-1 my-5 rounded text-white border${props.estado == 1
        ? " bg-secondary border-secondary"
        : props.estado > 1 && props.estado < 4
          ? " bg-primary border-primary"
          : " bg-success border-success"
        }`}
      onClick={() =>
        props.permission.includes(2) &&
          props.permissionSupervisor.includes(2) &&
          props.estado < 4
          ? props.range(props)
          : props.permissionSupervisor.includes(2) && props.estado === 3
            ? props.range(props)
            : props.permission.includes(2) && props.estado < 3
              ? props.range(props)
              : null
      }
      style={{
        cursor:
          props.permission.includes(2) &&
            props.permissionSupervisor.includes(2) &&
            props.estado < 4
            ? "pointer"
            : props.permissionSupervisor.includes(2) && props.estado === 3
              ? "pointer"
              : props.permission.includes(2) && props.estado < 3
                ? "pointer"
                : "default",
      }}
    >
      <span className="h6">{props.porcentaje}%</span>
    </div>
  );
};

export const moduleGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "modulos.nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
];
export const programGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "url",
    headerText: "URL",
    width: "135",
    textAlign: "Center",
  },
];

export const logsGrid = [
  {
    field: "registro",
    headerText: "Registro",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "usuario.email",
    headerText: "Usuario",
    width: "100",
    textAlign: "Left",
  },
  {
    field: "modulo.nombre",
    headerText: "Modulo",
    width: "75",
    textAlign: "Left",
  },
  {
    field: "fecha",
    headerText: "Fecha",
    width: "70",
    textAlign: "Left",
  },
  {
    field: "ip",
    headerText: "IP",
    width: "100",
    textAlign: "Left",
  },
];

export const DocumentsGrid = [
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "left",
  },
  {
    template: downloadDocuments,
    headerText: "",
    width: "30",
    textAlign: "Center",
  },
  {
    template: deleteDocuments,
    headerText: "",
    width: "30",
    textAlign: "Center",
  },
];

export const IndicatorsGrid = [
  {
    field: "nombre",
    headerText: "Nombre",
    width: "100",
    textAlign: "left",
  },
  {
    field: "meta",
    headerText: "Meta",
    width: "90",
    textAlign: "left",
  },
  {
    field: "verificacion",
    headerText: "Fuente de verificación",
    width: "90",
    textAlign: "left",
  },
  {
    template: bar,
    headerText: "Avance",
    width: "50",
    textAlign: "Center",
  },
  {
    template: state,
    headerText: "Estado",
    width: "50",
    textAlign: "Center",
  },
  {
    template: deleteIndicators,
    headerText: "",
    width: "20",
    textAlign: "Center",
  },
];

export const expensesGrid = [
  {
    template: rubro,
    headerText: "Rubro",
    width: "400",
    textAlign: "Center",
  },
  {
    template: concepto,
    headerText: "Concepto",
    width: "400",
    textAlign: "Center",
  },
  {
    template: financiado,
    headerText: "Financiado por",
    width: "300",
    textAlign: "Center",
  },
  {
    template: valorpresupuesto,
    headerText: "Valor Presupuestado",
    width: "135",
    textAlign: "Center",
  },
  {
    template: valorreal,
    headerText: "Valor Ejecutado",
    width: "135",
    textAlign: "Center",
  },
  {
    template: saldo,
    headerText: "Saldo",
    width: "135",
    textAlign: "Center",
  },
];

export const activitiesGrid = [
  {
    field: "nombre",
    headerText: "Nombre",
    width: "225",
    textAlign: "Center",
  },
  /* {
    field: "descripcion",
    headerText: "Descripción",
    width: "150",
    textAlign: "Center",
  }, */
  {
    template: activityAccion,
    headerText: "",
    width: "50",
    textAlign: "absolute",
  },
];

export const userGroupsGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
];
export const permissionsGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "grupoUsuarios.nombre",
    headerText: "Grupo de Usuario",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "modulosAcciones.modulos.nombre",
    headerText: "Módulo",
    width: "135",
    textAlign: "Center",
  },
];

export const sessionGrid = [
  {
    field: "fecha_sesion",
    headerText: "Fecha Sesión",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "token_sesion",
    headerText: "Token",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "sesion_id",
    headerText: "Sesión",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "usuarios.email",
    headerText: "Usuario",
    width: "135",
    textAlign: "Center",
  },
];

export const projectGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
  {
    template: projectPresupuesto,
    headerText: "Presupuesto",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "entidadEjecutora.razon_social",
    headerText: "Entidad Ejecutora",
    width: "135",
    textAlign: "Center",
  },
];

export const executingEntitiesGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nit",
    headerText: "NIT",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "razon_social",
    headerText: "Razón Social",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "nombre",
    headerText: "Persona de Contacto",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "Correo electrónico",
    width: "170",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "telefono",
    headerText: "Teléfono",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
];

export const memberProjectGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    template: memberName,
    headerText: "Nombre",
    width: "200",
    textAlign: "Center",
  },
  {
    field: "miembros.documento",
    headerText: "Documento",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "responsabilidad",
    headerText: "Responsabilidad",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
  {
    template: startDate,
    headerText: "Fecha inicio",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
  {
    template: endDate,
    headerText: "Fecha Finalización",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
  {
    template: porcentaje,
    headerText: "Dedicación",
    width: "135",
    textAlign: "Center",
  },
];
export const scheduleGrid = [
  {
    field: "nombre",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
  {
    template: startDate,
    headerText: "Fecha inicio",
    width: "135",
    textAlign: "Center",
  },
  {
    template: endDate,
    headerText: "Fecha Finalización",
    width: "135",
    textAlign: "Center",
  },
  {
    template: stateActivity,
    headerText: "Estado",
    width: "135",
    textAlign: "Center",
  },
];

export const memberGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombres",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "apellidos",
    headerText: "Apellido",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "documento",
    headerText: "Documento",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "Correo electrónico",
    width: "170",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "telefono",
    headerText: "Teléfono",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },
];

export const userGrid = [
  {
    type: "checkbox",
    field: "selected",
    textAlign: "Center",
    width: "100",
    headerText: "Select",
  },
  {
    field: "nombres",
    headerText: "Nombre",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "apellidos",
    headerText: "Apellido",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "documento",
    headerText: "Documento",
    width: "135",
    textAlign: "Center",
  },
  {
    field: "email",
    headerText: "Correo electrónico",
    width: "170",
    format: "yMd",
    textAlign: "Center",
  },
  {
    field: "telefono",
    headerText: "Teléfono",
    width: "135",
    format: "yMd",
    textAlign: "Center",
  },

  {
    field: "grupoUsuarios.nombre",
    headerText: "Rol",
    width: "120",
    textAlign: "Center",
  },
];



export const themeColors = [
  {
    name: "blue-theme",
    color: "#0071bc",
  },
  {
    name: "green-theme",
    color: "#fcea10",
  },
  {
    name: "purple-theme",
    color: "#c1d911",
  },
  {
    name: "red-theme",
    color: "#666666",
  },
];
