import React from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import axios from "../../../api";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";

import {
  addPresupuestoProyectoExcel,
  EditPresupuestoMensualProyecto,
} from "../../../data/api";
import { useEffect } from "react";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  project,
  date,
  user
}) => {
  const [file, setFile] = React.useState("");
  const [idDate, setIdDate] = React.useState("");
  const [monthlyBudget, setMonthlyBudget] = React.useState([]);
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setIdDate("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    clearFields();
  };

  useEffect(()=>{
    getMonthlyBudget()
  },[show])

  const mesnum = (mes) => {
    if (mes === "enero") {
      return "01";
    }
    if (mes === "febrero") {
      return "02";
    }
    if (mes === "marzo") {
      return "03";
    }
    if (mes === "abril") {
      return "04";
    }
    if (mes === "mayo") {
      return "05";
    }
    if (mes === "junio") {
      return "06";
    }
    if (mes === "julio") {
      return "07";
    }
    if (mes === "agosto") {
      return "08";
    }
    if (mes === "septiembre") {
      return "09";
    }
    if (mes === "octubre") {
      return "10";
    }
    if (mes === "noviembre") {
      return "11";
    }
    if (mes === "diciembre") {
      return "12";
    }
    Swal.fire({
      icon: "error",
      text: "Revise Fechas del presupuesto",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      readExcel(file);
    }
  };

  const readExcel = async (file) => {
    const fileReader = await new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = async (e) => {
      const bufferArray = e?.target.result;
      const wb = XLSX.read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws);
      const fileName = file.name.split(".")[0];

      let check = checkExcel(data);

      if (check) {
        if (editMode) {
          if (Object.keys(data[0]).length === 3) {
            if(monthlyBudget.includes(idDate)){
              Swal.fire({
                icon: "error",
                text: "Ya se agrego presupuesto en esa fecha",
                showConfirmButton: false,
                timer: 3000,
              });
            }else{
              await EditPresupuestoMensualProyecto(
                data,
                token,
                setLoading,
                get,
                setShow,
                project.id,
                idDate,
                file,
                `${project.nombre}-${idDate} ${new Date()
                  .toISOString()
                  .substring(0, 10)}`
              );
            }            
          } else {
            Swal.fire({
              icon: "error",
              text: "Revisar el Formato del Documento",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        } else {
          let result = true;
          let mes = "";
          let mesExcel = "";
          let ano = "";
          let anoExcel = "";
          for (let i = 0; i < date.length; i++) {
            mes = date[i].id.split("-")[0];
            ano = date[i].id.split("-")[1];
            mesExcel = mesnum(
              Object.keys(data[0])[i + 4].split("-")[0].toLowerCase()
            );
            anoExcel = Object.keys(data[0])[i + 4].split("-")[1];
            if (mes !== mesExcel || ano !== anoExcel) {
              result = false;
            }
          }      
          if (result) {
            await addPresupuestoProyectoExcel(
              data,
              token,
              setLoading,
              get,
              setShow,
              project.id,
              file,
              `${project.nombre} ${new Date()
                .toISOString()
                .substring(0, 10)}`
            );
          } else {
            Swal.fire({
              icon: "error",
              text: "Revisar el Formato del Documento",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          text: check,
          showConfirmButton: false,
          timer: 3000,
        });
      }

      return data;
    };
  };


  const getMonthlyBudget =async ()=>{
    try {
      const { data } = await axios.post(
        `/budgetSupport/getbudgetSupports`,
        { vp_tipo_soporte_presupuesto:2,id_proyectos: project.id },
        {
          headers: { "access-token": token },
        }
      );
      let info=[];
      data.documents.map((item) =>
      info.push(item.fecha))
      setMonthlyBudget(info);
      setLoading(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los gastos del Proyecto",
        showConfirmButton: false,
        timer: 3000,
      });
    }
}

  const checkExcel = (data) => {
    let tamano = Object.keys(data[0]).length;
    let text = true;
    let num = true;
    let vacio = true;
    for (let i = 0; i < data.length; i++) {
      if (tamano !== Object.keys(data[i]).length) {
        vacio = false;
      }
      for (let j = 0; j < tamano ; j++) {
        let prueba = Object.keys(data[0])[j];
        if (prueba === "Rubro" || prueba === "Concepto") {
          if (typeof data[i][prueba] !== "string") {
            text = false;
          }
        } else {
          if (typeof data[i][prueba] !== "number") {
            num = false;
          }
        }
      }
    }   
    
    if (vacio && num && text) {
      return true;
    } else {
      if (vacio === false) {
        return "Debe verificar que no existan campos vacios";
      }
      if (num === false) {
        return "Debe verificar que todos los valores del presupuesto sean numeros";
      }
      if (text === false) {
        return "Debe verificar que todas las categorias e item categorias sean textos";
      }
    }
  };
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          {editMode
            ? "Agregar Presupuesto Mensual"
            : "Agregar Presupuesto del Proyecto"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {editMode ? (
            <Row className="mb-3 d-flex justify-content-start">
              <Form.Group as={Col} controlId="validationCustom02">
                <FloatingLabel
                  controlId="idSpace"
                  label="Fecha"
                  className="col-12"
                >
                  <Form.Select
                    onChange={(e) => {
                      setIdDate(e.target.value);
                    }}
                    value={idDate}
                    placeholder="Mes"
                  >
                    <option defaultValue></option>
                    {date.map((item) => (
                      <option value={item.id}>{item.nombre}</option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                  Debe seleccionar un mes.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          ) : null}
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom01">
              <Form.Label>Subir Archivo</Form.Label>
              <Form.Control
                id="files"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                accept=".xlsx,.xlsm"
                required
              />
              <Form.Control.Feedback type="invalid">
                El Documento es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn rounded"
              variant="outline-success"
              type="submit"
            >
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
