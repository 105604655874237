import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ModalActivitiesChart = ({ show, setShow, data, setLoading }) => {
  const prueba = [];

  const handleClose = () => {
    setShow(false);
  };

  const formatDate = (date) => {
    const newDate = new Date(date /* .replace('T',' ').replace('Z',' ') */);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${
      newDate.getMonth() + 1 < 10 ? "0" : ""
    }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };

  const oneMonth = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return `${newDate.getFullYear()}-${newDate.getMonth() + 1 < 10 ? "0" : ""}${
      newDate.getMonth() + 1
    }-01 00:00:00`;
  };

  const getActivities = () => {
    const actividades = [];
    if (data !== "") {
      let fecha = data.fecha_inicio;
      const fechafin = data.fecha_fin;
      data?.objetivos.map((item) => {
        item?.actividades.map((actividad) => {
          actividades.push(actividad);
        });
      });
      if (actividades.length !== 0) {
        let dato = {};
        let ejecutado = [];
        let planificado = [];
        let day;
        while (fecha < fechafin) {
          planificado = actividades.filter((item) => item.fecha_fin < fecha);
          ejecutado = actividades.filter(
            (item) => item.fecha_fin < fecha && item.vp_estado_actividad === 4
          );
          dato = {
            name: formatDate(fecha),
            ejecutado: new Date(fecha) < new Date() ? ejecutado.length : null,
            planificado: planificado.length,
          };
          prueba.push(dato);
          fecha = oneMonth(fecha);
        }
        planificado = actividades;
        ejecutado = actividades.filter(
          (item) => item.vp_estado_actividad === 4
        );
        dato = {
          name: formatDate(fechafin),
          ejecutado: new Date(fecha) < new Date() ? ejecutado.length : null,
          planificado: planificado.length,
        };
        prueba.push(dato);
      } else {
        handleClose();
        Swal.fire({
          icon: "info",
          text: "No hay datos para mostrar",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  useEffect(() => {
    getActivities();
  }, [show]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>
          Grafica de Actividades
          <box-icon
            name="x"
            onClick={() => handleClose()}
            style={{
              position: "absolute",
              right: "20px",
              cursor: "pointer",
            }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <ResponsiveContainer width="100%" aspect={2 / 1}>
            <LineChart
              width={730}
              height={250}
              data={prueba}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="planificado"
                stroke="#666666"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="ejecutado"
                stroke="#0071bc"
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalActivitiesChart;
