import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { executingEntitiesGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import { DeleteExecutingEntity, ModalInfoExecutingEntity } from "../../components/Modals";
// import ModalInfoExcutingEntity from "../../components/Modals/executingEntity/ModalInfoExecutingEntity";
import Swal from "sweetalert2";
import { loginRequest } from "../../actions";

const ExecutingEntities = (props) => {
  const { token, userId, groupId, permission, loginRequest } = props;
  const [ExcutingEntities, setExecutingEntities] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editId, setEditId] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRegister, setModalResiter] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const exportExcel = async () => {
    const arrayExcel = await ExcutingEntities?.map((item) => {
      const xlsx = {
        "Razón Social": item.razon_social || "",
        NIT: item.nit,
        "Persona de Contacto": item.nombre,
        "Correo electrónico": item.email,
        Teléfono: item.telefono || "",
      };
      return xlsx;
    });
    await exportToExcel(arrayExcel, "Entidades Ejecutoras");
  };

  const deleteList = () => {
    if (selected.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const edit = () => {
    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selected.length === 1) {
      setEditId(selected[0].id);
      setShowModalEdit(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un usuario para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerExcutingEntities = () => {
    setModalResiter(true);
  };

  const deleteExcutingEntities = {
    align: "Left",
    click: deleteList,
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: permission.includes(4),
    width: "auto",
  };

  const addExcutingEntities = {
    align: "Left",
    click: registerExcutingEntities,
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: permission.includes(2),
    width: "auto",
  };

  const EditExcutingEntities = {
    align: "Left",
    click: edit,
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: permission.includes(3),
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: permission.includes(5),
    width: "auto",
  };

  const toolbarOptions = [
    "Search",
    addExcutingEntities,
    EditExcutingEntities,
    deleteExcutingEntities,
    excelExport,
  ];

  const searchOptions = {
    fields: [
      "razon_social",
      "nombre",
      "email",
      "documento",
      "nit",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getExcitingEntities = async () => {
    setLoading(true);
    await axios
      .post(
        "/executingEntity/getExecutingEntities",
        {},
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setExecutingEntities(data?.executingEntities);
        setEditId(0);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err.response.data.mensaje === "Invalid Token") {
          Swal.fire({
            icon: "error",
            text: "Error al validar usuario.",
            showConfirmButton: false,
            timer: 3000,
          });
          loginRequest();
          history("/");
        } else {
          Swal.fire({
            icon: "error",
            text: "Ocurrio un error al consultar las entidades Ejecutoras.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getExcitingEntities();      
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelected([...selected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) => item.id !== data.id);
        setSelected(selectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selected.includes(item.id));
        setSelected([...selected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) =>
          data.includes(item.id)
        );
        setSelected(selectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <DeleteExecutingEntity
        show={showModalDelete}
        setShow={setShowModalDelete}
        selected={selected}
        userId={userId}
        token={token}
        setSelected={setSelected}
        get={getExcitingEntities}
        setLoading={setLoading}
      />
      <ModalInfoExecutingEntity
        show={showModalRegister}
        setShow={setModalResiter}
        token={token}
        get={getExcitingEntities}
        setLoading={setLoading}
      />
      <ModalInfoExecutingEntity
        show={showModalEdit}
        setShow={setShowModalEdit}
        token={token}
        get={getExcitingEntities}
        editMode
        editId={editId}
        setLoading={setLoading}
      />
      <Header category="Equipo de Trabajo" title="Entidad Ejecutora" />
      <GridComponent
        dataSource={ExcutingEntities}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
      >
        <ColumnsDirective>
          {executingEntitiesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 2)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToprops = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToprops)(ExecutingEntities);
