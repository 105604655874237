import React from 'react'
import { Button, Modal, FloatingLabel, Form, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from "../../../api";

const ModalInfoMember = ({ show, setShow, token, editMode, editId, groupId, userId, getUsers, setLoading }) => {
    const [nombres, setNombres] = React.useState('')
    const [apellidos, setApellidos] = React.useState('')
    const [documento, setDocumento] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [validated, setValidated] = React.useState(false);

    const clearFields = () => {
        setNombres('');
        setApellidos('');
        setTelefono('');
        setEmail('');
        setDocumento('');
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (editMode) {
                editUser()
            } else {
                addUser()
            }
        }
    };

    const editUser = () => {
        const newUser = {
            nombres,
            apellidos,
            documento,
            telefono,
            email,
        }
        
        handleClose()
        setLoading(true)
        axios.put(`/member/${editId}`,
            { ...newUser, userId },
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(async () => {
                    setLoading(false);
                    if (res.data.memberUpdated) {
                        clearFields()
                        getUsers();
                        Swal.fire({
                            icon: "success",
                            text: "Editado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true)
                        Swal.fire({
                            icon: "error",
                            text: res.data.userUpdated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                    setShow(true)
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido editar.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }, 2000);
                
            });
    }

    const addUser = () => {
        const newUser = {
            nombres,
            apellidos,
            documento,
            telefono,
            email,
        }
        handleClose();
        setLoading(true);
        axios.post(`/member/`,
            { ...newUser, userId },
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(()=>{
                    setLoading(false);
                    if (res.data.memberCreated) {
                        clearFields()
                        getUsers();
                        Swal.fire({
                            icon: "success",
                            text: "Creado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true);
                        Swal.fire({
                            icon: "error",
                            text: res.data.userCreated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                },2000);
            })
            .catch((error) => {
                setTimeout(()=>{
                    setLoading(false);
                    setShow(true);
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido crear.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                },2000);
                console.error(error)
                
            });
    }

    const getUser = async () => {
        try {// si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
            const { data } = await axios.get(`/member/${editId}`, {
                headers: { "access-token": token },
            });
            setNombres(data.member?.nombres)
            setApellidos(data.member?.apellidos)
            setDocumento(data.member?.documento)
            setTelefono(data.member?.telefono)
            setEmail(data.member?.email)
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                text: "Error al Consultar los datos del Usuario",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const soloNumeros = (e) => {
        const key = e.charCode;
        if (!(key >= 48 && key <= 57)) {
            e.preventDefault();
        }
    }


    React.useEffect(() => {
        if (editId !== 0 && editId !== undefined) {
            getUser()
        }
    }, [editId])

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{editMode ? 'Editar' : 'Crear'} Miembro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <FloatingLabel
                                controlId="nombres"
                                label="Nombres"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={nombres} onChange={(e) => { setNombres(e.target.value) }} placeholder="Nombres" required />
                                <Form.Control.Feedback type="invalid">
                                    Los nombre son obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <FloatingLabel
                                controlId="apellidos"
                                label="Apellidos"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={apellidos} onChange={(e) => { setApellidos(e.target.value) }} placeholder="Apellidos" required />
                                <Form.Control.Feedback type="invalid">
                                    Los Apellidos Son Obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <FloatingLabel
                                controlId="documento"
                                label="Documento"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={documento} onChange={(e) => { setDocumento(e.target.value) }} onKeyPress={(e) => { soloNumeros(e) }} placeholder="Documento" required />
                                <Form.Control.Feedback type="invalid">
                                    El Documento es Obligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <FloatingLabel
                                controlId="telefono"
                                label="Telefono"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={telefono} onChange={(e) => { setTelefono(e.target.value) }} onKeyPress={(e) => { soloNumeros(e) }} placeholder="Telefono" />
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} controlId="validationCustom05">
                            <FloatingLabel
                                controlId="email"
                                label="Correo electrónico"
                                className="mb"
                            >
                                <Form.Control type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Correo electónico" required />
                                <Form.Control.Feedback type="invalid">
                                    El correo eslectronico es obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Modal.Footer>
                        <Button className="btn rounded" variant="outline-dark" onClick={handleClose}>
                            Cancelar
                        </Button>
                        {
                            editMode ?
                                (<Button className="btn rounded" variant="outline-warning" type="submit">
                                    Editar
                                </Button>)
                                :
                                (<Button className="btn rounded" variant="outline-success" type="submit">
                                    Registrar
                                </Button>)
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoMember