import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { Button, Row, FloatingLabel, Form } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ModalExpensesChart = ({ show, setShow, data, financial, rubro }) => {
  const [chart, setChart]= useState([]);
  const [rubroFilter, setRubroFilter] = useState("");
  const [financialFilter, setFinancialFilter] = useState("");

  const handleClose = () => {
    setShow(false);
    setFinancialFilter("");
    setRubroFilter("")
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${newDate.getMonth() + 1 < 10 ? "0" : ""
      }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };
  const formatDateExpense = (mes, ano) => {
    const newDate = new Date(`${ano}-${mes}-28`);
    return `${newDate.getFullYear()}-${newDate.getMonth() + 1 < 10 ? "0" : ""}${newDate.getMonth() + 1
      }-${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()} 00:00:00`;
  };

  const oneMonth = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return `${newDate.getFullYear()}-${newDate.getMonth() + 1 < 10 ? "0" : ""}${newDate.getMonth() + 1
      }-01 00:00:00`;
  };
  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  });

  const total = (array, total) => {
    let sum = 0;

    if (total) {
      for (let i = 0; i < array.length; i++) {
        sum += parseFloat(array[i].valorpresupuesto);
      }
    } else {
      for (let i = 0; i < array.length; i++) {
        sum += parseFloat(array[i].valorreal);
      }
    }
    return sum;
  };

  const getActivities = (info) => {
    if (data !== "") {
      let prueba=[]
      if (info.length !== 0) {
        let fecha = data.project?.fecha_inicio;
        const fechafin = data.project?.fecha_fin;
        let dato = {};
        let ejecutado = [];
        let planificado = [];
        let day;
        while (fecha < fechafin) {
          planificado = info.filter(
            (item) => formatDateExpense(item.mes, item.ano) < fecha
          );
          ejecutado = info.filter(
            (item) => item.fecha_fin < fecha && item.vp_estado_actividad === 3
          );
          dato = {
            name: formatDate(fecha),
            ejecutado: total(planificado, false),
            planificado: total(planificado, true),
          };
          prueba.push(dato);
          fecha = oneMonth(fecha);
        }
        dato = {
          name: formatDate(fechafin),
          ejecutado: total(info, false),
          planificado: total(info, true),
        };
        prueba.push(dato);
        setChart(prueba)
      } else {
        handleClose();
        Swal.fire({
          icon: "info",
          text: "No hay datos para mostrar",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  useEffect(() => {
    getActivities(data.expenses);
  }, [show]);
  const filterExpenses = () => {
    const filtered = data.expenses.filter(
      (item) => {
        if (rubroFilter !== "" && rubroFilter != item.itemcategoria.categorias.id) {
          return false
        }
        if (financialFilter !== "" && financialFilter !== item.vp_financiamiento) {
          return false
        }
        return true

      }
    );
    getActivities(filtered)
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const planificadoValue = payload[0]?.value || 0;
      const ejecutadoValue = payload[1]?.value || 0;

      return (
        <div style={{ backgroundColor: "#ffffff" }}>
          <p className="label">{label}</p>
          <p className="label" style={{ color: "#666666" }}>{`Planificado: ${formatterPeso.format(planificadoValue)}`}</p>
          <p className="label" style={{ color: "#0071bc" }}>{`Ejecutado: ${formatterPeso.format(ejecutadoValue)}`}</p>
          <p className="label" style={{ color: planificadoValue - ejecutadoValue >= 0 ? "#008f39" : "#ff0000" }}>
            {`Diferencia: ${formatterPeso.format(planificadoValue - ejecutadoValue)}`}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>
          Grafica de Presupuesto
          <box-icon
            name="x"
            onClick={() => handleClose()}
            style={{
              position: "absolute",
              right: "20px",
              cursor: "pointer",
            }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3 d-flex justify-content-start">
          <FloatingLabel controlId="idSpace" label="Rubro" className="col-5">
            <Form.Select
              onChange={(e) => {
                setRubroFilter(e.target.value);
              }}
              value={rubroFilter}
              placeholder="Mes"
            >
              <option defaultValue></option>
              {rubro.map((item) => (
                <option value={item.id}>{item.nombre}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="idSpace" label="Financiado por" className="col-5">
            <Form.Select
              onChange={(e) => {
                setFinancialFilter(e.target.value);
              }}
              value={financialFilter}
              placeholder="Mes"
            >
              <option defaultValue></option>
              {financial.map((item) => (
                <option value={item.valor_parametro}>{item.valor_parametro}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <Button
            variant="outline-primary"
            className="col-md-2 btn rounded"
            onClick={filterExpenses}
          >
            Consultar
          </Button>
        </Row>
        <div>
          <ResponsiveContainer width="100%" aspect={2 / 1}>
            <LineChart
              width={730}
              height={250}
              data={chart}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line type="monotone" dataKey="planificado" stroke="#666666" strokeWidth={2} />
              <Line type="monotone" dataKey="ejecutado" stroke="#0071bc" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalExpensesChart;
