import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import { editActivity,editIndicator } from "../../../data/api";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
  indicator=false,
}) => {
  const [porcentaje, setPorcentaje] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setPorcentaje("");
  };

  React.useEffect(() => {
    setPorcentaje(data.porcentaje);
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      handleClose();
      let info = {
        porcentaje,
      };
      if(indicator){
        if (data.estado === 1) {
          info = {
            ...info,
            estado: 2,
          };
        }
        editIndicator(data.id, info, token, setLoading, get, setShow);
      }else{        
        if (data.vp_estado_actividad === 1) {
          info = {
            ...info,
            vp_estado_actividad: 2,
          };
        }
        editActivity(data.id, info, token, setLoading, get, setShow);
      }
      clearFields();
    }
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Porcentaje de avance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Title>Rango: {porcentaje}%</Modal.Title>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <Form.Range
                max={90}
                min={1}
                value={porcentaje}
                onChange={(e) => {
                  setPorcentaje(e.target.value);
                }}
                placeholder="porcentaje"
                required
              />
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn rounded"
              variant="outline-success"
              type="submit"
            >
              Actualizar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
