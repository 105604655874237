import React from 'react'
import { Button, Modal, FloatingLabel, Form, Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from "../../../api";

const ModalInfoExecutingEntity = ({ show, setShow, token, editMode, editId, get, setLoading }) => {
    const [razonSocial, setRazonSocial] = React.useState('')
    const [nit, setNit] = React.useState('')
    const [nombre, setNombre] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [validated, setValidated] = React.useState(false);

    const clearFields = () => {
        setRazonSocial('');
        setNit('');
        setTelefono('');
        setEmail('');
        setNombre('');
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        } else {
            if (editMode) {
                editUser()
            } else {
                addUser()
            }
        }
    };

    const editUser = () => {
        const razon_social= razonSocial
        const newUser = {
            razon_social,
            nit,
            nombre,
            telefono,
            email,
        }
        
        handleClose()
        setLoading(true)
        axios.put(`/executingEntity/${editId}`,
            { ...newUser},
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(async () => {
                    setLoading(false);
                    if (res.data.updated) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Editado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true)
                        Swal.fire({
                            icon: "error",
                            text: res.data.userUpdated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }, 2000);
            })
            .catch((error) => {
                console.error(error);
                setTimeout(() => {
                    setLoading(false);
                    setShow(true)
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido editar.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }, 2000);
                
            });
    }

    const addUser = () => {
            const razon_social= razonSocial
        const newUser = {
            razon_social,
            nit,
            nombre,
            telefono,
            email,
        }
        handleClose();
        setLoading(true);
        axios.post(`/executingEntity/`,
            { ...newUser},
            {
                headers: { "access-token": token },
            }
        )
            .then((res) => {
                setTimeout(()=>{
                    setLoading(false);
                    if (res.data.executingEntity) {
                        clearFields()
                        get();
                        Swal.fire({
                            icon: "success",
                            text: "Creado exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        setShow(true);
                        Swal.fire({
                            icon: "error",
                            text: res.data.userCreated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                },2000);
            })
            .catch((error) => {
                setTimeout(()=>{
                    setLoading(false);
                    setShow(true);
                    Swal.fire({
                        icon: "error",
                        text: "No se ha podido crear.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                },2000);
                console.error(error)
                
            });
    }

    const getExecutingEntity = async () => {
        try {// si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
            const { data } = await axios.get(`/executingEntity/${editId}`, {
                headers: { "access-token": token },
            });
            setRazonSocial(data.executingEntity?.razon_social)
            setNit(data.executingEntity?.nit)
            setNombre(data.executingEntity?.nombre)
            setTelefono(data.executingEntity?.telefono)
            setEmail(data.executingEntity?.email)
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                text: "Error al Consultar los datos del Usuario",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const soloNumeros = (e) => {
        const key = e.charCode;
        if (!(key >= 48 && key <= 57)) {
            e.preventDefault();
        }
    }

    React.useEffect(() => {
        if (editId !== 0 && editId !== undefined) {
            getExecutingEntity()
        }
    }, [editId])

    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>{editMode ? 'Editar' : 'Crear'} Entidad Ejecutora</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <FloatingLabel
                                controlId="razon_social"
                                label="Razón Social"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={razonSocial} onChange={(e) => { setRazonSocial(e.target.value) }} placeholder="razonSocial" required />
                                <Form.Control.Feedback type="invalid">
                                    El campo es obligatorio.
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <FloatingLabel
                                controlId="nit"
                                label="NIT"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={nit} onChange={(e) => { setNit(e.target.value) }} onKeyPress={(e) => { soloNumeros(e) }} placeholder="nombre" required />
                                <Form.Control.Feedback type="invalid">
                                    El campo es obligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                    <Modal.Title>Persona de Contacto</Modal.Title>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <FloatingLabel
                                controlId="nombre"
                                label="Nombre"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={nombre} onChange={(e) => { setNombre(e.target.value) }} placeholder="nombre" required />
                                <Form.Control.Feedback type="invalid">
                                    El campo es obligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom04">
                            <FloatingLabel
                                controlId="telefono"
                                label="Telefono"
                                className="mb"
                                md="6"
                            >
                                <Form.Control md="6" type="text" value={telefono} onChange={(e) => { setTelefono(e.target.value) }} onKeyPress={(e) => { soloNumeros(e) }} placeholder="Telefono" />
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col} controlId="validationCustom05">
                            <FloatingLabel
                                controlId="email"
                                label="Correo electrónico"
                                className="mb"
                            >
                                <Form.Control type="text" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Correo electónico" required />
                                <Form.Control.Feedback type="invalid">
                                    El campo es obligatorio
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </Row>
                    
                    <Modal.Footer>
                        <Button className="btn rounded" variant="outline-dark" onClick={handleClose}>
                            Cancelar
                        </Button>
                        {
                            editMode ?
                                (<Button className="btn rounded" variant="outline-warning" type="submit">
                                    Editar
                                </Button>)
                                :
                                (<Button className="btn rounded" variant="outline-success" type="submit">
                                    Registrar
                                </Button>)
                        }
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalInfoExecutingEntity