import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { expensesGrid } from "../../data/dummy";
import axios from "../../api";
import Swal from "sweetalert2";
import { Header, Backdrop } from "../../components";
import { decrypt } from "../../utils/crypt.js";
import { Tooltip } from "@material-ui/core";
import ModalExpensesChart from "../../components/Modals/chart/ModalExpensesChart";
import ModalExpense from "../../components/Modals/expenses/ModalExpense";
import ModalListDocuments from "../../components/Modals/documents/ModalListDocuments";
import ModalDocumentos from "../../components/Modals/documents/ModalDocuments";
import iconAddInforme from "../../assets/icon/agregar_informe.png";
import iconAddDocumento from "../../assets/icon/agregar_tarea.png";
import iconGrafica from "../../assets/icon/graficas.png";
import iconViewInforme from "../../assets/icon/visualizar_informe.png";
import iconViewDocumento from "../../assets/icon/visualizar_tarea.png";
import { Button, Row, FloatingLabel, Form } from "react-bootstrap";
import fileDownload from "js-file-download";
import { MdWhereToVote } from "react-icons/md";
import { deletePresupuesto } from "../../data/api";

const InfoProjects = (props) => {
  const params = useParams();
  const {
    token,
    userId,
    groupId,
    permissionDocuments,
    permissionDocumentsSupervisor,
    user
  } = props;
  const [project, setProject] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [rubro, setRubro] = useState([]);
  const [financial, setFinancial] = useState([]);
  const [listDate, setListDate] = useState([]);
  const [expensesFilter, setExpensesFilter] = useState([]);
  const [modalAddExpense, setModalAddExpense] = useState(false);
  const [date, setDate] = useState("");
  const [rubroFilter, setRubroFilter] = useState("");
  const [financialFilter, setFinancialFilter] = useState("");
  const [addMonthlyExpense, setAddMonthlyExpense] = useState(false);
  const [modalAddCategory, setModalAddCategory] = useState(false);
  const [modalAddItemCategory, setModalAddItemCategory] = useState(false);
  const [modalDocumentos, setModalDocumentos] = useState(false);
  const [modalExpensesChart, setModalExpensesChart] = useState(false);
  const [modalAddDocument, setModalAddDocument] = React.useState(false);
  const [supervisor, setSupervisor] = React.useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (permissionDocuments.includes(1) && params.id) {
      getProject();
      getExpenses();
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  const exportExcel = async () => {
    const arrayExcel = await expensesFilter?.map((item) => {
      const budget = {
        Rubro: item.itemcategoria.categorias.nombre,
        Concepto: item.itemcategoria.nombre,
        "Valor Planificado": parseFloat(item.valorpresupuesto),
        "Valor Ejecutado": parseFloat(item.valorreal),
        "Saldo": parseFloat(item.valorpresupuesto - item.valorreal)
      };
      return budget;
    });
    await exportToExcel(
      arrayExcel,
      `${mes(expensesFilter[0].mes)}-${expensesFilter[0].ano} ${project.nombre}`
    );
  };
  const exportFormatExcelMonth = async () => {
    const filtered = expenses.filter(
      (item) => item.mes == listDate[0].id.split("-")[0] && item.ano == listDate[0].id.split("-")[1]
    );
    const arrayExcel = await filtered?.map((item) => {
      const budget = {
        Rubro: item.itemcategoria.categorias.nombre,
        Concepto: item.itemcategoria.nombre,
        Valor: 0,
      };
      return budget;
    });
    await exportToExcel(
      arrayExcel,
      `Formato presupuesto Mensual ${project.nombre}`
    );
  };

  const obtenerLetraTotal = (cantidadColumnas) => {
    const letraInicial = 'E'; // Cambia según tus necesidades
    const codigoInicial = letraInicial.charCodeAt(0);
    const letraFinal = String.fromCharCode(codigoInicial + cantidadColumnas - 1);
    return letraFinal;
  };
  const exportFormatExcel = async () => {
    let column = {};
    const letraTotal = obtenerLetraTotal(listDate.length)
    column['Rubro'] = ""
    column['Concepto'] = ""
    column['Financiado'] = ""
    column['Total'] = { t: 'n', f: `SUM(E2:${letraTotal}2)` }
    listDate.map((item) => column[item.nombre] = "")
    const arrayExcel = [column];
    await exportToExcel(
      arrayExcel,
      `Formato presupuesto ${project.nombre}`

    );
  };

  const exportProjectBudget = async () => {
    try {
      const { data } = await axios.post(
        `/budgetSupport/getbudgetSupports`,
        { vp_tipo_soporte_presupuesto: 1, id_proyectos: decrypt(params?.id) },
        {
          headers: { "access-token": token },
        }
      );
      download(data.documents[0].ruta);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al descargar el presupuesto del proyecto",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteBudget = () => {
    handelClick("delete", "Presupuesto", null);
  };

  const registerBudget = () => {
    handelClick("add", "gasto", project);
  };

  const deleteUser = {
    align: "Left",
    click: deleteBudget,
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: expenses.length > 0 ? true : false,
    width: "auto",
  };

  const addUser = {
    align: "Left",
    click: registerBudget,
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: expenses.length > 0 ? "Agregar Presupuesto Mensual" : " Agregar Presupuesto Proyecto",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: expensesFilter.length > 0 ? true : false,
    width: "auto",
  };
  const excelFormatExportMonth = {
    align: "Left",
    click: expenses.length > 0 ? exportFormatExcelMonth : exportFormatExcel,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: expenses.length > 0 ? "Formato Mensual" : "Formato Presupuesto",
    tooltipText: "ExcelExport",
    type: "Button",
    width: "auto",
  };
  const projectBudget = {
    align: "Left",
    click: exportProjectBudget,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Presupuesto Proyecto",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: expenses.length > 0 ? true : false,
    width: "auto",
  };

  const toolbarOptions = [
    "Search",
    addUser,
    deleteUser,
    excelExport,
    excelFormatExportMonth,
    projectBudget,
  ];

  const searchOptions = {
    fields: ["itemcategoria.categorias.nombre", "itemcategoria.nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const download = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.substr(url?.lastIndexOf("=") + 1));
      });
  };


  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelectedUsers([...selectedUsers, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter(
          (item) => item.id !== data.id
        );
        setSelectedUsers(selectedUsersFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selectedUsers.includes(item.id));
        setSelectedUsers([...selectedUsers, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter((item) =>
          data.includes(item.id)
        );
        setSelectedUsers(selectedUsersFilter);
      }
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${newDate.getMonth() + 1 < 10 ? "0" : ""
      }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };

  const getProject = async () => {
    try {
      const { data } = await axios.get(`/project/${decrypt(params?.id)}`, {
        headers: { "access-token": token },
      });
      setProject(data.project);
      getListDate(data.project.fecha_inicio, data.project.fecha_fin);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Proyecto",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const mes = (mes) => {
    if (mes == "01") {
      return "enero";
    }
    if (mes == "02") {
      return "febrero";
    }
    if (mes == "03") {
      return "marzo";
    }
    if (mes == "04") {
      return "abril";
    }
    if (mes == "05") {
      return "mayo";
    }
    if (mes == "06") {
      return "junio";
    }
    if (mes == "07") {
      return "julio";
    }
    if (mes == "08") {
      return "agosto";
    }
    if (mes == "09") {
      return "septiembre";
    }
    if (mes == "10") {
      return "octubre";
    }
    if (mes == "11") {
      return "noviembre";
    }
    if (mes == "12") {
      return "diciembre";
    }
  };

  const oneMonth = (date) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);
    return `${newDate.getFullYear()}-${newDate.getMonth() + 1 < 10 ? "0" : ""}${newDate.getMonth() + 1
      }-01 00:00:00`;
  };

  function changeDayOfDate(fechaString) {
    let [date, time] = fechaString.split(' ');
    let [year, month, day] = date.split('-');
    day = '01';

    return `${year}-${month}-${day} ${time}`;
  }
  const getListDate = (fInicio, fFin) => {
    let fecha = changeDayOfDate(fInicio);
    let obj = {};
    let array = [];
    while (fecha < fFin) {
      obj = {
        id: `${fecha.split("-")[1]}-${fecha.split("-")[0]}`,
        nombre: `${mes(fecha.split("-")[1])}-${fecha.split("-")[0]}`,
      };
      array.push(obj);
      fecha = oneMonth(fecha);
    }
    array.shift()
    setListDate(array);
  };

  const getExpenses = async () => {
    try {
      const { data } = await axios.post(
        `/expense/getexpenses`,
        { id_proyectos: decrypt(params?.id) },
        {
          headers: { "access-token": token },
        }
      );
      setExpenses(
        data?.data?.expenses
      );
      setFinancial(data?.data?.financial)
      setRubro(data?.data?.categories)
      setLoading(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los gastos del Proyecto",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const get = () => {
    getProject();
    setExpensesFilter([]);
    getExpenses();
    setAddMonthlyExpense(false)
  };
  const filterExpenses = () => {
    if (date === "") {
      Swal.fire({
        icon: "info",
        text: "La fecha es obligatoria",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      const filtered = expenses.filter(
        (item) => {
          if (rubroFilter !== "" && rubroFilter != item.itemcategoria.categorias.id) {
            return false
          }
          if (financialFilter !== "" && financialFilter !== item.vp_financiamiento) {
            return false
          }
          if (item.mes == date.split("-")[0] && item.ano == date.split("-")[1]) {
            return true
          }

        }
      );
      if (filtered.length > 0) {
        const sumaTotal = filtered.reduce((acumulador, objeto) => {
          acumulador.valorpresupuesto += parseFloat(objeto.valorpresupuesto.replace(',', ''));
          acumulador.valorreal += parseFloat(objeto.valorreal.replace(',', ''));
          return acumulador;
        }, { valorpresupuesto: 0, valorreal: 0 });
        filtered.unshift({ ...sumaTotal, itemcategoria: { categorias: { nombre: 'Total' }, nombre: "Total del mes" }, vp_financiamiento: "Total" });
        setExpensesFilter(
          filtered
        );
      }else{
        setRubroFilter("")
        setDate("")
        setFinancialFilter("")
        setExpensesFilter([]);
        Swal.fire({
          icon: "info",
          text: "No existe registro con los filtros aplicados",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }

  };

  const handelClick = (action, element, info) => {
    setData(info);
    switch (action) {
      case "add":
        switch (element) {
          case "gasto":
            if (expenses.length > 0) {
              setAddMonthlyExpense(true);
            }
            setModalAddExpense(!modalAddExpense);
            break;
          case "categoria":
            setModalAddCategory(!modalAddCategory);
            break;
          case "itemcategoria":
            setModalAddItemCategory(!modalAddItemCategory);
            break;
          case "documentos":
            setModalAddDocument(!modalAddDocument);
            break;
          case "informe":
            setSupervisor(!supervisor);
            setModalAddDocument(!modalAddDocument);
            break;
        }
        break;
      case "edit":
        switch (element) {
        }
        break;
      case "delete":
        Swal.fire({
          text: `¿Está seguro que desea eliminar este ${element}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d3c221",
          cancelButtonColor: "#282828",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            deletePresupuesto(project.id, token, setLoading, get);
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }
        });
        break;
      case "view":
        switch (element) {
          case "graficaGastos":
            setModalExpensesChart(!modalExpensesChart);
            break;
          case "documentos":
            setModalDocumentos(!modalDocumentos);
            break;
          case "supervisor":
            setSupervisor(!supervisor);
            setModalDocumentos(!modalDocumentos);
            break;
        }
        break;
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  });

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalExpense
        show={modalAddExpense}
        setShow={setModalAddExpense}
        token={token}
        get={get}
        setLoading={setLoading}
        project={data}
        editMode={addMonthlyExpense}
        date={listDate}
        user={user}
      />
      <ModalExpensesChart
        show={modalExpensesChart}
        setShow={setModalExpensesChart}
        data={data}
        financial={financial}
        rubro={rubro}
      />
      <ModalListDocuments
        show={modalDocumentos}
        setShow={setModalDocumentos}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        supervisor={supervisor}
        setSupervisor={setSupervisor}
        permission={
          supervisor ? permissionDocumentsSupervisor : permissionDocuments
        }
      />
      <ModalDocumentos
        show={modalAddDocument}
        setShow={setModalAddDocument}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        supervisor={supervisor}
        setSupervisor={setSupervisor}
      />
      <Tooltip title="Atras">
        <box-icon
          name="left-arrow-alt"
          onClick={() =>
            project.vp_programa == 1
              ? history(`/diie-atlantico`)
              : project.vp_programa == 2
                ? history(`/criie-guajira`):
                project.vp_programa==3?
                 history(`/criie-cordoba`)
                 : history(`/diie-videojuegos`)

          }
        />
      </Tooltip>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Tooltip title="Agregar Informe Supervision">
          <div
            onClick={() => handelClick("add", "informe", project.id)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocumentsSupervisor.includes(2)
                ? null
                : "none",
            }}
          >
            <img
              src={iconAddInforme}
              alt="Añadir Informe"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Agregar Documento">
          <div
            onClick={() => handelClick("add", "documentos", project.id)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocuments.includes(2) ? null : "none",
            }}
          >
            <img
              src={iconAddDocumento}
              alt="Añadir Documento"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Informes Financieros">
          <div
            onClick={() => handelClick("view", "supervisor", project.id)}
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img
              src={iconViewInforme}
              alt="Ver Informe"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Documentos">
          <div
            onClick={() => handelClick("view", "documentos", project.id)}
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img
              src={iconViewDocumento}
              alt="Ver Informe"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Grafica">
          <div
            onClick={() =>
              handelClick("view", "graficaGastos", { expenses, project })
            }
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img src={iconGrafica} alt="Ver Grafica" style={{ width: 50 }} />
          </div>
        </Tooltip>
      </div>
      <Header
        category={
          project.vp_programa == 1
            ? `Diie Atlantico - ${project?.entidadEjecutora?.razon_social}`
            : project.vp_programa == 2
              ? `Criie Guajira - ${project?.entidadEjecutora?.razon_social}`
              : `Criie Cordoba - ${project?.entidadEjecutora?.razon_social}`
        }
        title={project.nombre}
      />
      <p className="h6">
        <span className="fw-bold">Duración del proyecto:</span>{" "}
        {formatDate(project.fecha_inicio)} - {formatDate(project.fecha_fin)}
      </p>
      {/* <p className="h6">
        <span className="fw-bold">Presupuesto: </span>{" "}
        {formatterPeso.format(project.presupuesto)}
      </p> */}
      <p className="h6 fw-bold">Alcance</p>
      <p className="text-justify">
        <em>{project.alcance}</em>
      </p>
      <p className="h6 fw-bold">Objetivo General</p>
      <p className="text-justify">
        <em>{project.objetivo_general}</em>
      </p>
      <br />
      {expenses.length > 0 ? (
        <Row className="mb-3 d-flex justify-content-start">
          <FloatingLabel controlId="idSpace" label="Fecha*" className="col-2">
            <Form.Select
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={date}
              placeholder="Mes"
            >
              <option defaultValue></option>
              {listDate.map((item) => (
                <option value={item.id}>{item.nombre}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="idSpace" label="Rubro" className="col-5">
            <Form.Select
              onChange={(e) => {
                setRubroFilter(e.target.value);
              }}
              value={rubroFilter}
              placeholder="Mes"
            >
              <option defaultValue></option>
              {rubro.map((item) => (
                <option value={item.id}>{item.nombre}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="idSpace" label="Financiado por" className="col-3">
            <Form.Select
              onChange={(e) => {
                setFinancialFilter(e.target.value);
              }}
              value={financialFilter}
              placeholder="Mes"
            >
              <option defaultValue></option>
              {financial.map((item) => (
                <option value={item.valor_parametro}>{item.valor_parametro}</option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <Button
            variant="outline-primary"
            className="col-md-2 btn rounded"
            onClick={filterExpenses}
          >
            Consultar
          </Button>
        </Row>
      ) : null}
      <p style={{ fontStyle: 'italic', fontSize: 14 }}> La filas resaltadas indican diferencias entre el valor presupuestado y el valor ejecutado*</p>
      <GridComponent
        dataSource={expensesFilter}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
      >
        <ColumnsDirective>
          {expensesGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permissionDocuments: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 4)
      .map((item) => item.modulosAcciones?.id_acciones),
    permissionDocumentsSupervisor: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 5)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};
export default connect(mapStateToProps, null)(InfoProjects);
