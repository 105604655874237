import React, { useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest, setPermissions, setToken } from "../../actions";
import {
  makeStyles,
  Grid,
  Button,
  CssBaseline,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "../../api";
import Swal from "sweetalert2";
import HomeBackground from "../../assets/fondo.jpg";
import Backdrop from "../../components/Backdrop";
import Logo from "../../assets/Logo_CRIIE.png";
import {decrypt} from "../../utils/crypt"
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

function ChangePass(props) {
  const { token } = props;
  const history = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = decrypt(params?.id);
    setLoading(true);
    if (password === confirmPassword) {
      if (password.length<6) {
        Swal.fire({
          icon: "error",
          text: 'la contraseña debe terner al menos 6 caracteres.',
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (confirmPassword.length<6) {
        Swal.fire({
          icon: "error",
          text: 'la confirmacion contraseña debe terner al menos 6 caracteres.',
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const form={
        confirmPassword,
        password
      }
      axios
        .put(`/user/changePassword/forget/${userId}`, { form, token})
        .then((res) => {
          setLoading(false);
          if (res.data.userUpdated.success) {
            Swal.fire({
              icon: "success",
              text: "Contraseña actualizada exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              history("/");
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.userUpdated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: "Se ha vencido el tiempo de espera.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: "Las contraseñas ingresadas no coinciden.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image}>
      </Grid>
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.containerLogo}>
            <img alt="logo" src={Logo} />
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                style={{ fontWeight: "bolder", letterSpacing: "-1px", color: "#032f3c" }}
                className="text-center"
              >
                Cambiar Contraseña
              </Typography>
            </Grid>
          </Grid>
          <Form className={classes.form} onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="mb-2">
              <Form.Group as={Col} md="12" controlId="validationDate">
                <FloatingLabel controlId="email" label="Contraseña" className="mb">
                  <Form.Control
                    type="password"
                    name="email"
                    autoComplete="email"
                    required
                    variant="outlined"
                    onChange={(e) =>{setPassword(e.target.value)} }
                    value={password}
                  />
                  <Form.Control.Feedback type="invalid">
                    El campo es obligatorio
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} md="12" controlId="validationDate">
                <FloatingLabel controlId="password" label="Confirmar Contraseña" className="mb">
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    required
                    variant="outlined"
                    onChange={(e) =>{setConfirmPassword(e.target.value)}}
                    value={confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    El campo es obligatoria
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="justify-content-center mb-2">
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.submit}
              >
                Cambiar Contraseña
              </Button>
            </Row>
          </Form>
        </div>
      </Grid>
      {
        loading&&(
          <Backdrop />
        )
      }
      
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(16, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(17, 6),
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    width: "13em",
    fontWeight: "bolder",
    borderRadius: "10px",
    "background-color": "#032f3c",
    "&:hover": {
      background: "#1b4a61"
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
  containerLogo: {
    margin: "-3em 0 3em 0",
  },
  textField: {
    margin: "11em 1em 0 1em",
    color: theme.palette.background.main,
    fontWeight: "bolder",
    fontSize: "42px",
    textShadow: "2px 2px 2px black",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToprops = {
  loginRequest,
  setPermissions,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToprops)(ChangePass);
