import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import { FaLaptopHouse } from "react-icons/fa";
import {
  addObjective,
  editObjective,
  addDeliverable,
  uploadFileEjecutor,
  editDeliverable,
  addIndicators,
  uploadFile,
} from "../../../data/api";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
  name,
}) => {
  /* const [descripcion, setDescripcion] = React.useState(""); */
  const [file, setFile] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    /* setDescripcion(""); */
    setNombre("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setFile("")
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      let info = {
        /* descripcion, */
        nombre,
      };
      if (name == "Objetivo") {
        info = {
          ...info,
          id_proyectos: editMode ? data.id_proyectos : data,
        };
      }
      if (name == "Entregable") {
        info = {
          ...info,
          id_actividades: editMode ? data.id_actividades : data,
          ruta: "",
        };
      }
      if (editMode) {
        if (name == "Objetivo") {
          editObjective(data.id, info, token, setLoading, get, setShow);
        }
        if (name == "Entregable") {
          /* editDeliverable(data.id, info, token, setLoading, get, setShow,file); */
          uploadFileEjecutor(data.id, token, setLoading, get, setShow, file);
        }
      } else {
        if (name == "Objetivo") {
          addObjective(info, token, setLoading, get, setShow);
        }
        if (name == "Entregable") {
          addDeliverable(info, token, setLoading, get, setShow, file);
        }
      }
      handleClose();
      clearFields();
    }
  };

  React.useEffect(() => {
    if (editMode) {
      /* setDescripcion(data.descripcion); */
      setNombre(data.nombre);
    }
  }, [data]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Editar" : "Crear"}
          {" " + name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <FloatingLabel controlId="nombre" label="Nombre" className="mb">
                <Form.Control
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="nombre"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          {/* <Row className="mb-2">
            <Form.Group as={Col}>
              <FloatingLabel
                controlId="descripcion"
                label="Descripción"
                className="mb"
              >
                <Form.Control
                  as="textarea"
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                  style={{ height: "100px" }}
                  placeholder="descripcion"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row> */}
          {name === "Entregable" && (
            <Row className="mb-2">
              <Form.Group as={Col}>
                <Form.Label>Entregable</Form.Label>
                <Form.Control
                  id="files"
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  accept=".pdf,.xlsx,"
                />
                <Form.Control.Feedback type="invalid">
                  La foto es obligatorio.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          )}
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
