import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import { addDocuments } from "../../../data/api";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
  supervisor,
  setSupervisor,
  documentTecnico = false,
}) => {
  const [tipoDocument, setTipoDocument] = React.useState("");
  const [categoriaDocumento, setCategoriaDocumento] = React.useState("");
  const [periodo, setPeriodo] = React.useState("");
  const [categorias, setCategorias] = React.useState([]);
  const [file, setFile] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [validated, setValidated] = React.useState(false);
  const periodos = [
    { value: 1, name: "Primer Periodo" },
    { value: 2, name: "Segundo Periodo" },
    { value: 3, name: "Tercer Periodo" },
    { value: 4, name: "Cuarto Periodo" },
    { value: 5, name: "Quinto Periodo" },
    { value: 6, name: "Sexto Periodo" },
  ];

  const clearFields = () => {
    setNombre("");
    setTipoDocument("");
    setCategoriaDocumento("");
    setPeriodo("");
    setCategorias([]);
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setSupervisor(false);
    clearFields();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      let info = {
        nombre: nombre.trim() === "" ? file.name : nombre,
        id_proyectos: data,
        ruta: "",
        periodo,
      };
      let url;
      if (tipoDocument == 1) {
        url = "/technicalSupport/";
        info = {
          ...info,
          vp_tipo_soporte_tecnico: categoriaDocumento,
        };
      }
      if (tipoDocument == 2) {
        url = "/financialSupport/";
        info = {
          ...info,
          vp_tipo_soporte_financieros: categoriaDocumento,
        };
      }
      if (tipoDocument == 3) {
        url = "/administrativeSupport/";
        info = {
          ...info,
          vp_tipo_soporte_administrativo: categoriaDocumento,
        };
      }
      if (tipoDocument == 4) {
        url = "/changeControl/";
        info = {
          ...info,
          vp_tipo_soporte_control_cambio: categoriaDocumento,
        };
      }
      if (supervisor) {
        url = "/supervisorSupport/";
        info = {
          ...info,
          vp_tipo_soporte_supervisor: documentTecnico ? 1 : 2,
        };
      }
      addDocuments(info, token, setLoading, get, setShow, url, file);
      handleClose();
    }
  };

  const document = () => {
    const tecnico = [
      {
        value: 1,
        categoria: "Evidencias de ejecución",
      },
      {
        value: 2,
        categoria: "Informe tecnico",
      },
      {
        value: 3,
        categoria: "Acta de reunión",
      },
    ];
    const financiero = [
      {
        value: 1,
        categoria: "Informe Financiero",
      },
      {
        value: 2,
        categoria: "Factura",
      },
      {
        value: 3,
        categoria: "Comprobante",
      },
    ];
    const administrativo = [
      {
        value: 1,
        categoria: "Contratos",
      },
      {
        value: 2,
        categoria: "Polizas",
      },
      {
        value: 3,
        categoria: "Orden de servicio",
      },
    ];
    const cambio = [
      {
        value: 1,
        categoria: "Solicitud de modificación",
      },
      {
        value: 2,
        categoria: "Acta de aceptación de cambios",
      },
    ];

    if (tipoDocument == 1) {
      setCategorias(tecnico);
    }
    if (tipoDocument == 2) {
      setCategorias(financiero);
    }
    if (tipoDocument == 3) {
      setCategorias(administrativo);
    }
    if (tipoDocument == 4) {
      setCategorias(cambio);
    }
  };

  React.useEffect(() => {
    document();
  }, [tipoDocument]);
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Agregar Documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {supervisor ? null : (
            <>
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <FloatingLabel
                    controlId="grupUser"
                    label="Tipos documento"
                    className="mb"
                  >
                    <Form.Select
                      onChange={(e) => {
                        setTipoDocument(e.target.value);
                      }}
                      value={tipoDocument}
                      required
                    >
                      <option defaultValue></option>
                      {documentTecnico ? (
                        <>
                          <option value={1}>Soportes Tecnicos</option>
                          <option value={4}>Control de cambio</option>
                        </>
                      ) : (
                        <>
                          <option value={2}>Soportes Financieros</option>
                          <option value={3}>Soportes Administrativos</option>
                        </>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      El campo es obligatorio.
                    </Form.Control.Feedback>
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <FloatingLabel
                    controlId="grupUser"
                    label="Categoria"
                    className="mb"
                  >
                    <Form.Select
                      onChange={(e) => {
                        setCategoriaDocumento(e.target.value);
                      }}
                      value={categoriaDocumento}
                      required
                    >
                      <option defaultValue></option>
                      {categorias.map((item) => (
                        <option value={item.value}>{item.categoria}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      El campo es obligatorio.
                    </Form.Control.Feedback>
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </>
          )}
          <Row className="mb-2">
            <Form.Group as={Col}>
              <FloatingLabel controlId="periodo" label="Periodo" className="mb">
                <Form.Select
                  onChange={(e) => {
                    setPeriodo(e.target.value);
                  }}
                  value={periodo}
                  required
                >
                  <option defaultValue></option>
                  {periodos.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <FloatingLabel controlId="nombre" label="Nombre" className="mb">
                <Form.Control
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="nombre"
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Form.Label>Entregable</Form.Label>
              <Form.Control
                id="files"
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                accept=".pdf,.xlsx,"
                required
              />
              <Form.Control.Feedback type="invalid">
                La foto es obligatorio.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn rounded"
              variant="outline-success"
              type="submit"
            >
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
