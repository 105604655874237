import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import {
  addIndicators
} from "../../../data/api";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
}) => {
  const [nombre, setNombre] = React.useState("");
  /* const [descripcion, setDescripcion] = React.useState(""); */
  const [meta, setMeta] = React.useState("");
  const [verificacion, setVerificacion] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setNombre("");
    /* setDescripcion(""); */
    setMeta("");
    setVerificacion("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      let info = {
        nombre,
        meta,
        verificacion,
        /* descripcion, */
        id_entregables:editMode?data_id_entregable:data,
      };
      handleClose();
      if (editMode) {
      } else {
        addIndicators(info,token, setLoading, get,setShow)
      }
      clearFields();
    }
  };

  React.useEffect(() => {
    if (editMode) {
      setNombre(data.nombre);
    }
  }, [data]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Editar" : "Crear"} Indicador
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <FloatingLabel controlId="nombre" label="Nombre" className="mb">
                <Form.Control
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="nombre"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          {/* <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom01">
              <FloatingLabel
                controlId="descripcion"
                label="Descripción"
                className="mb"
              >
                <Form.Control
                  as="textarea"
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                  style={{ height: "100px" }}
                  placeholder="descripcion"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row> */}
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <FloatingLabel controlId="meta" label="Meta" className="mb">
                <Form.Control
                  type="text"
                  value={meta}
                  onChange={(e) => {
                    setMeta(e.target.value);
                  }}
                  placeholder="Meta"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <FloatingLabel controlId="verificacion" label="Fuente de verificación" className="mb">
                <Form.Control
                  type="text"
                  value={verificacion}
                  onChange={(e) => {
                    setVerificacion(e.target.value);
                  }}
                  placeholder="Verificación"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
