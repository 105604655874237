import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { sessionGrid } from "../../data/dummy";
import { Header } from "../../components";
import Swal from "sweetalert2";
import { Backdrop } from "../../components"

const Sessions = (props) => {
  const { token, userId, groupId, permission } = props;
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const toolbarOptions = ["Search"];

  const searchOptions = {
    fields: [
      "usuarios.email",
      "fecha_sesion"
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getSessions = async () => {
    setLoading(true)
    await axios.post(
      "/session/getSessions",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false)
      setSessions(data?.sessions);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar las Sesiones.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getSessions();
      setLoading(false);
    } else {
      setLoading(false)
      history("/") 
    }
  }, []);


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Configuración" title="Sesiones" />
      <GridComponent
        dataSource={sessions}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        allowTextWrap
      >
        <ColumnsDirective>
          {sessionGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {
        loading && (
          <Backdrop />
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 9)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(Sessions);
