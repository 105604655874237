import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IndicatorsGrid } from "../../../data/dummy";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";
import ModalRange from "../chart/ModalRange";
import { editIndicator } from "../../../data/api";

const ModalListIndicators = ({
  show,
  setShow,
  token,
  data,
  setLoading,
  permission,
  permissionSupervisor,
}) => {
  const [indicators, setIndicators] = React.useState([]);
  const [modalRange, setModalRange] = React.useState(false);
  const [dateRange, setDateRange] = React.useState("");
  const [value, setValue] = React.useState(0);

  const handleClose = () => {
    setShow(false);
  };

  const approve = (props) => {
    let text;
    if (props.estado === 1) {
      text = "¿Desea cambiar el estado a en curso?";
    }
    if (props.estado === 2) {
      text = "¿Desea solicitar la aprobación del indicador?";
    }
    if (props.estado === 3) {
      text = "¿Desea aprobar el indicador";
    }
    Swal.fire({
      text: text,
      icon: "warning",
      showCancelButton: true,
      showDenyButton: props.estado === 3 ? true : false,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      denyButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed || result.isDenied) {
        let info;
        if (result.value) {
          info = {
            estado: props.estado + 1,
          };
          if (props.estado + 1 === 4) {
            info = {
              ...info,
              porcentaje: 100,
            };
          }
        } else {
          info = {
            estado: props.estado - 1,
          };
        }
        editIndicator(props.id, info, token, setLoading, getIndicators, null);
      }
    });
  };

  const range = (props) => {
    setDateRange(props);
    setModalRange(true);
  };

  const getIndicators = async () => {
    setLoading(true);
    await axios
      .post(
        "/indicator/getIndicators",
        { id_entregables: data },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        if (data?.indicators.length) {
          setLoading(false);
          let obj = {};
          const info = [];
          data?.indicators.map((item) => {
            obj = {
              ...item,
              state: approve,
              permission,
              permissionSupervisor,
              range: range,
              daleteIndicador,
            };
            info.push(obj);
          });
          setIndicators(info);
          let sum = 0;
          for (let i = 0; i < data?.indicators.length; i++) {
            sum = sum + data?.indicators[i].porcentaje;
          }
          const result = Math.trunc(sum / data?.indicators.length);
          setValue(isNaN(result) ? 0 : result);
        } else {
          if(show){handleClose();
          Swal.fire({
            icon: "info",
            text: "No hay indicadores para mostrar",
            showConfirmButton: false,
            timer: 3000,
          });
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los indicadores.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const daleteIndicador = async (id) => {
    Swal.fire({
      text: `¿Está seguro que desea eliminar este indicador?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        try {
          setLoading(true);
          await axios
            .delete(`/indicator/${id}`, {
              headers: {
                "access-token": token,
              },
            })
            .then(({ data }) => {
              setLoading(false);
              if (data.deleted) {
                Swal.fire({
                  text: "Se a eliminado con exito.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
              getIndicators();
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                text: "error al eliminar.",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
              });
              setLoading(true);
            });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  React.useEffect(() => {
    getIndicators();
  }, [show]);

  const toolbarOptions = ["Search"];
  const searchOptions = {
    fields: ["nombre", "meta", "verificacion"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  return (
    <div>
      <ModalRange
        show={modalRange}
        setShow={setModalRange}
        token={token}
        get={getIndicators}
        setLoading={setLoading}
        data={dateRange}
        indicator={true}
      />
      <Modal show={show} size="xl">
        <Modal.Header>
          <Modal.Title>
            Lista de Indicadores
            <box-icon
              name="x"
              onClick={() => handleClose()}
              style={{
                position: "absolute",
                right: "20px",
                cursor: "pointer",
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: 200,
              height: 200,
              marginLeft: "auto",
              marginRight: "auto",
              padding: 10,
            }}
          >
            <CircularProgressbar
              value={value}
              text={`${value}%`}
              strokeWidth={10}
            />
          </div>

          <GridComponent
            dataSource={indicators}
            enableHover={false}
            allowPaging
            pageSettings={{ pageCount: 5 }}
            allowSorting
            toolbar={toolbarOptions}
            searchSettings={searchOptions}
          >
            <ColumnsDirective>
              {IndicatorsGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
          </GridComponent>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalListIndicators;
