import axios from "../../api";
import React from 'react'
import { Button, Modal, ListGroup, FloatingLabel, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DeleteUser = ({ show, setShow, selected, token, userId, setSelected, get, setLoading }) => {
    const handleClose = () => setShow(false);

    const modalDelete = async () => {
        Swal.fire({
            text: "¿Está seguro que desea eliminar este usuario?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d3c221",
            cancelButtonColor: "#282828",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.value) {
                handleClose()
                setLoading(true);                
                for (let index = 0; index < selected.length; index++) {
                    const element = selected[index];
                    await sendDelete(element.id);
                }
                setTimeout(()=>{
                    setLoading(false);
                },2000);
                setSelected([])
                get()                
            }
        });
    };

    const sendDelete = async (id) => {
        await axios.delete(`/userGroup/${id}`, {
            data: { id_usuarios: userId },
            headers: { "access-token": token },
        }).then(({data}) => {
            if (!data.groupDeleted) {
                Swal.fire({
                    text: "Ha ocurrido un error",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }).catch(err => {
            setLoading(false);
            console.error(err);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {selected.map((item) =>
                    (
                        <ListGroup.Item key={item.id}>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Nombre:"
                                className="mb"
                            >
                                <Form.Control type="text" value={item.nombre} disabled={true} />
                            </FloatingLabel>
                        </ListGroup.Item>
                    ))
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn rounded" variant="outline-dark" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button className="btn  rounded" variant="outline-danger" onClick={modalDelete}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteUser;
