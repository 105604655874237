import React from "react";
import ReactLoading from 'react-loading';
import { makeStyles,Modal} from "@material-ui/core";
const BackdropComponent = () => {
  const classes = useStyles();
  return (
    <Modal open={true} id="modalLoading" className={classes["modal-content"]} >
      <ReactLoading  className="mx-auto" type="spin" color="#FFF" height={'5%'} width={'5%'} />
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  "modal-content":{
    "position": "relative",
    "display": "flex",
    "flex-direction": "column",
    "width": "100%",
    "pointer-events": "auto",
    "background-color": "rgba(255, 255, 255, 0)",
    "background-clip": "padding-box",
    "border-style": "none",
    "outline": "0",
    "margin-top":"20%",
  }
}));

export default BackdropComponent;