import React from 'react'
import { Backdrop, Header } from '../../components'
import { Button, FloatingLabel, Form, Col, Row } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { connect } from "react-redux";
import axios from "../../api";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ProfileSettings = (props) => {
    const { token, userId, groupId, permission } = props;
    const [loading, setLoading] = React.useState(false);
    const [validated, setValidated] = React.useState(false);
    const [editable, setEditable] = React.useState(false);
    const [editablePass, setEditablePass] = React.useState(false);

    const [nombres, setNombres] = React.useState('')
    const [apellidos, setApellidos] = React.useState('')
    const [documento, setDocumento] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const history = useNavigate();


    React.useEffect(() => {
        if (token.length > 0) {
            getUser();
            setLoading(false);
        } else {
            setLoading(false)
            history("/")
        }
    }, []);

    const getUser = async () => {
        await axios.get(`/user/${userId}`, {
            headers: { "access-token": token },
        }).then(({ data }) => {
            setNombres(data.user?.nombres)
            setApellidos(data.user?.apellidos)
            setDocumento(data.user?.documento)
            setEmail(data.user?.email)
            setTelefono(data.user?.telefono)
        }).catch((error) => {
            console.error(error);
            Swal.fire({
                icon: "error",
                text: "Error al Consultar datos del Usuario.",
                showConfirmButton: false,
                timer: 3000,
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false && (editablePass || editable)) {
            e.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            if (editable) {
                editUser()
            } else if (editablePass) {
                editPass()
            }
        }
    };

    const editUser = () => {
        setLoading(true)
        axios.put(`/user/${userId}`,
            { nombres, apellidos, documento, email, telefono, userId },
            { headers: { "access-token": token } }
        )
            .then((res) => {
                setLoading(false);
                if (res.data.userUpdated.success) {
                    setEditable(false)
                    Swal.fire({
                        icon: "success",
                        text: "Editado exitosamente.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        text: res.data.userUpdated.message,
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            })
            .catch((error) => {
                console.error(error)
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    text: "No se ha podido editar.",
                    showConfirmButton: false,
                    timer: 3000,
                });
            });
    }

    const editPass = () => {
        const form = {
            password,
            confirmPassword
        }
        setLoading(true)
        if (form.password === form.confirmPassword) {
            axios.put(`/user/changePassword/forget/${userId}`, { form, token })
                .then((res) => {
                    setLoading(false);
                    if (res.data.userUpdated.success) {
                        setEditablePass(false)
                        Swal.fire({
                            icon: "success",
                            text: "Contraseña actualizada exitosamente.",
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: res.data.userUpdated.message,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: "Se ha vencido el tiempo de espera.",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                });
        } else {
            setLoading(false);
            Swal.fire({
                icon: "error",
                text: "Las contraseñas ingresadas no coinciden.",
                showConfirmButton: false,
                timer: 3000,
            });
        }
    }

    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl ">
            <Header category="CONFIGURACIÓN" title="Configuración De Usuario" />
            <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="form"
            >
                <Col className="" >
                    <Row className="mb-2 col-4" style={{ marginLeft: '30%' }}>
                        <FloatingLabel
                            controlId="nombres"
                            label="Nombres"
                            className="mb"
                            md="6">
                            <Form.Control
                                md="6"
                                type="text"
                                value={nombres}
                                onChange={(e) => { setNombres(e.target.value) }}
                                placeholder="Nombres"
                                disabled={!editable}
                                required />
                            <Form.Control.Feedback type="invalid">
                                Los nombre son obligatorio.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Row>
                    <Row className="mb-2 col-4" style={{ marginLeft: '30%' }}>
                        <FloatingLabel
                            controlId="apellidos"
                            label="Apellidos"
                            className="mb"
                            md="6">
                            <Form.Control
                                md="6"
                                type="text"
                                value={apellidos}
                                onChange={(e) => { setApellidos(e.target.value) }}
                                placeholder="Apellidos"
                                required
                                disabled={!editable} />
                            <Form.Control.Feedback type="invalid">
                                Los apellidos son obligatorio.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Row>
                    <Row className="mb-2 col-4" style={{ marginLeft: '30%' }}>
                        <FloatingLabel
                            controlId="documento"
                            label="Documento"
                            className="mb"
                            md="6">
                            <Form.Control
                                md="6"
                                type="text"
                                value={documento}
                                onChange={(e) => { setDocumento(e.target.value) }}
                                placeholder="documento"
                                required
                                disabled={!editable} />
                            <Form.Control.Feedback type="invalid">
                                Los documento son obligatorio.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Row>
                    <Row className="mb-2 col-4" style={{ marginLeft: '30%' }}>
                        <FloatingLabel
                            controlId="email"
                            label="Correo Electrónico"
                            className="mb"
                            md="6">
                            <Form.Control
                                md="6"
                                type="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                placeholder="Correo Electrónico"
                                required
                                disabled={!editable} />
                            <Form.Control.Feedback type="invalid">
                                Los correo electrónicoson obligatorio.
                            </Form.Control.Feedback>
                        </FloatingLabel>
                    </Row>
                    <Row className="mb-2 col-4" style={{ marginLeft: '30%' }}>
                        <FloatingLabel
                            controlId="telefono"
                            label="Telefono"
                            className="mb"
                            md="6">
                            <Form.Control
                                md="6"
                                type="text"
                                value={telefono}
                                onChange={(e) => { setTelefono(e.target.value) }}
                                placeholder="Telefono"
                                disabled={!editable} />
                        </FloatingLabel>
                    </Row>
                </Col>
                {
                    editablePass && (
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="validationCustom06">
                                <FloatingLabel
                                    controlId="contrasena"
                                    label="Contraseña"
                                    className="mb"
                                    md="6"
                                >
                                    <Form.Control
                                        md="6"
                                        type="password"
                                        value={password}
                                        onChange={(e) => { setPassword(e.target.value) }}
                                        placeholder="Contraseña"
                                        required />
                                    <Form.Control.Feedback type="invalid">
                                        La contraseña es obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom07">
                                <FloatingLabel
                                    controlId="confirmPassword"
                                    label="Confirmar Contraseña"
                                    className="mb"
                                    md="6"
                                >
                                    <Form.Control
                                        md="6"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                        placeholder="Confirmar Contraseña"
                                        required />
                                    <Form.Control.Feedback type="invalid">
                                        La contraseña es obligatorio.
                                    </Form.Control.Feedback>
                                </FloatingLabel>

                            </Form.Group>
                        </Row>
                    )
                }
                <div className="mt-5">
                    <Col className="flex justify-content-center">
                        {
                            !editable && !editablePass ?
                                (
                                    <>
                                        <Button
                                            className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                            variant="outline-success"
                                            type="button"
                                            id="editar"
                                            onClick={() => { setEditable(true) }}>
                                            <AiOutlineEdit className="align-self-center" /> Editar Informacion
                                        </Button>
                                        <Button
                                            className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                            variant="outline-primary"
                                            onClick={() => { setEditablePass(true) }}> Cambiar Contraseña
                                        </Button>

                                    </>
                                )
                                : editablePass
                                    ?
                                    (
                                        <>
                                            <Button
                                                className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                                variant="outline-dark"
                                                onClick={() => { setEditablePass(false) }}> Cancelar
                                            </Button>
                                            <Button
                                                className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                                variant="outline-warning"
                                                type="submit"
                                                id="actualizar">
                                                <AiOutlineEdit className="align-self-center" /> Actualizar
                                            </Button>
                                        </>
                                    )
                                    : editable && (
                                        <>
                                            <Button
                                                className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                                variant="outline-dark"
                                                onClick={() => { setEditable(false) }}> Cancelar
                                            </Button>
                                            <Button
                                                className="btn rounded-full mx-2 align-self-center d-flex justify-content-center"
                                                variant="outline-warning"
                                                type="submit" >
                                                <AiOutlineEdit className="align-self-center" /> Actualizar
                                            </Button>
                                        </>
                                    )
                        }

                    </Col>
                </div>
            </Form>
            {
                loading && (
                    <Backdrop />
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        token: state.token,
        userId: state.user?.id,
        groupId: state.user?.id_grupos_usuarios,
        permission: (state.permission || [])
            .filter((data) => data.modulosAcciones?.id_modulos === 2)
            .map((item) => item.modulosAcciones?.id_acciones),
    };
};

export default connect(mapStateToProps, null)(ProfileSettings);