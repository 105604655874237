import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";

const ModalActivity = ({
  show,
  setShow,
  token,
  editMode = false,
  editId,
  get,
  setLoading,
  data,
  min,
  max,
}) => {
  const [nombre, setNombre] = React.useState("");
  const [fechaInicio, setFechaInicio] = React.useState("");
  const [fechaFinalizacion, setFechaFinalizacion] = React.useState("");
  /* const [descripcion, setDescripcion] = React.useState(""); */
  const [estadoActividad, setEstadoActividad] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setNombre("");
    setFechaInicio("");
    setFechaFinalizacion("");
    /* setDescripcion(""); */
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      const fecha_inicio = fechaInicio + " 12:00:00";
      const fecha_fin = fechaFinalizacion + " 12:00:00";
      /* const descripcion_tecnica = descripcion; */
      let info = {
        nombre,
        /* descripcion_tecnica, */
        fecha_inicio,
        fecha_fin,
        vp_estado_actividad: editMode ? estadoActividad : 1,
        id_objetivos: editMode ? data.id_objetivos : data,
      };
      if (editMode) {
        editActivity(info);
      } else {
        const fecha_inicio_esperado = fechaInicio + " 12:00:00";
        const fecha_fin_esperado = fechaFinalizacion + " 12:00:00";
        info = { ...info, fecha_inicio_esperado, fecha_fin_esperado };
        addActivity(info);
      }
    }
  };

  const editActivity = (info) => {
    handleClose();
    setLoading(true);
    axios
      .put(
        `/activity/${data.id}`,
        { ...info },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(async () => {
          setLoading(false);
          if (res.data.updated) {
            clearFields();
            get();
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userUpdated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
      });
  };

  const addActivity = (info) => {
    handleClose();
    setLoading(true);
    axios
      .post(
        `/activity/`,
        { ...info },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          if (res.data.activity) {
            clearFields();
            get();
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userCreated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
        console.error(error);
      });
  };

  React.useEffect(() => {
    if (editMode) {
      setNombre(data?.nombre);
      // Fecha Local
      /* setFechaInicio(data.fecha_inicio?.replace(" 12:00:00", ""));
      setFechaFinalizacion(data.fecha_fin?.replace(" 12:00:00", "")); */
      //Fecha Servidor
      setFechaInicio(data.fecha_inicio?.replace(" 07:00:00", ""));
      setFechaFinalizacion(data.fecha_fin?.replace(" 07:00:00", ""));
      /* setDescripcion(data?.descripcion_tecnica); */
      setEstadoActividad(data?.vp_estado_actividad);
    }
  }, [data]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{editMode ? "Editar" : "Crear"} Actividad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <FloatingLabel
                controlId="nombre"
                label="Nombre Actividad"
                className="mb"
                md="12"
              >
                <Form.Control
                  md="12"
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="Nombre Actividad"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha inicial" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                  placeholder="Fecha inicial"
                  //Ver Fechas en Local
                  /* min={min?.replace(" 12:00:00", "")}
                  max={max?.replace(" 12:00:00", "")} */
                  //Ver Fechas en Servidor
                  min={min?.replace(" 07:00:00", "")}
                  max={max?.replace(" 07:00:00", "")}
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha Final" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaFinalizacion}
                  onChange={(e) => setFechaFinalizacion(e.target.value)}
                  placeholder="Fecha Final"
                  min={fechaInicio}
                  //Ver Fechas en Local
                  /* max={max?.replace(" 12:00:00", "")} */
                  //Ver Fechas en Servidor
                  max={max?.replace(" 07:00:00", "")}
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          {/* <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom05">
              <FloatingLabel
                controlId="descripcion"
                label="Descripción"
                className="mb"
              >
                <Form.Control
                  as="textarea"
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                  style={{ height: "100px" }}
                  placeholder="Descripción"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row> */}
          {editMode ? (
            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustom08">
                <FloatingLabel
                  controlId="vp_estado_activity"
                  label="Estado Actividad"
                  className="mb"
                >
                  <Form.Select
                    onChange={(e) => {
                      setEstadoActividad(e.target.value);
                    }}
                    value={estadoActividad}
                    required
                  >
                    <option defaultValue></option>
                    <option value={1}>Pendiente</option>
                    <option value={2}>En proceso</option>
                    <option value={3}>Finalizado</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    El campo es obligatorio.
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </FloatingLabel>
              </Form.Group>
            </Row>
          ) : null}
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalActivity;
