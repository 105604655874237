import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../contexts/ContextProvider";
import logo from "../assets/LogoProjectPlace.png";
import { connect } from "react-redux";
import { BiGridAlt, BiUserPin } from "react-icons/bi";
import { IoMdContacts } from "react-icons/io";
import { GrInsecure } from "react-icons/gr";
import { FaUserEdit } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { MdRestore } from "react-icons/md";
import axios from "../api";

const Sidebar = (props) => {
  const { permisos, program } = props;
  const linkProgram = program.map((programa) => ({
    name: programa.programas.nombre,
    url: programa.programas.url,
    id: 3,
    icon: <GoGraph />, 
  }));
  const links = [
    {
      title: "Equipo de Trabajo",
      links: [
        {
          name: "Usuarios",
          url: "users",
          id: 1,
          icon: <BiUserPin />,
        },
        {
          name: "Programas",
          url: "program",
          id: 20,
          icon: <BiUserPin />,
        },
        {
          name: "Usuarios - Programas",
          url: "userProgram",
          id: 21,
          icon: <BiUserPin />,
        },
        {
          name: "Miembros",
          url: "members",
          id: 1,
          icon: <BiUserPin />,
        },
        {
          name: "Entidad Ejecutora",
          url: "executing-entities",
          id: 2,
          icon: <BiUserPin />,
        },
      ],
    },
    {
      title: "Proyectos",
      links: linkProgram
    },
    {
      title: "CONFIGURACIÓN",
      links: [
        {
          name: "Grupos de usuarios",
          url: "userGroups",
          id: 6,
          icon: <IoMdContacts />,
        },
        {
          name: "Módulos",
          url: "modules",
          id: 7,
          icon: <BiGridAlt />,
        },
        {
          name: "Permisos",
          url: "permissions",
          id: 8,
          icon: <GrInsecure />,
        },
        /* {
          name: 'Parámetros',
          url: 'parameters',
          id: 6,
          icon: <TiFlowParallel />,
        }, */
        {
          name: "Log",
          url: "log",
          id: 19,
          icon: <MdRestore />,
        },
        {
          name: "Sesiones",
          url: "sessions",
          id: 9,
          icon: <MdRestore />,
        },
      ],
    },
    {
      title: "Perfil",
      links: [
        {
          name: "Configuración De Perfil",
          url: "profile/edit",
          id: 3,
          icon: <FaUserEdit />,
        },
      ],
    },
  ];

  
  const modulos = permisos
    .filter((e) => e.modulosAcciones.acciones.nombre === "Leer")
    .map((e) => e.modulosAcciones.id_modulos);

  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const filterTitle = (item) => {
    let result = false;
    item.links.forEach((link) => {
      if (modulos.includes(link.id)) {
        result = true;
      }
    });
    return result;
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div
            className="flex justify-between items-center"
            style={{ marginRight: "5%" }}
          >
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
              style={{ marginLeft: "25%" }}
            >
              <img src={logo} alt="logo Criee" width={130} height={130} />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          {links.map((item) =>
            filterTitle(item) ||
            item.links.filter((item) => item.id === undefined).length > 0 ? (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => {
                  return link?.id ? (
                    modulos.includes(link.id) ? (
                      <NavLink
                        to={`/${link.url}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                          borderRadius: 100,
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        {link.icon}
                        <span className="capitalize ">{link.name}</span>
                      </NavLink>
                    ) : (
                      <div key={link.name}></div>
                    )
                  ) : (
                    <NavLink
                      to={`/${link.url}`}
                      key={link.name}
                      onClick={handleCloseSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? currentColor : "",
                        borderRadius: 100,
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className="capitalize ">{link.name}</span>
                    </NavLink>
                  );
                })}
              </div>
            ) : (
              <div key={item.title}></div>
            )
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    program: state.user.usuariosProgramas,
    permisos: state.permission || [],
    user: state.user,
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(Sidebar);
