import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import {
  Users,
  SignIn,
  Projects,
  ExecutingEntities,
  InfoProjects,
  FinanceProjects,
  Activities,
  UserGroup,
  Module,
  Session,
  Permissions,
  ProfileSettings,
  NotFound,
  MemberProject,
  Member,
  ChangePass,
  Logs,
  FormParameters,
  Program,
  UserProgram,
  Parameters
} from "./pages";
import { useStateContext } from "./contexts/ContextProvider";
import { Layout, Backdrop } from "./components"
import { connect } from "react-redux";
import "./App.css";

const App = (props) => {
  const {  program } =
    props;
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div>
          <Routes>
            <Route exact path="account/forget/:id" element={<ChangePass />} />
            <Route
              exact
              path="/"
              element={<Navigate to="/sign-in" replace={true} />}
            />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route
              path="/users"
              element={
                <Layout>
                  <Users />
                </Layout>
              }
            />
            <Route
              path="/program"
              element={
                <Layout>
                  <Program />
                </Layout>
              }
            />
            <Route
              path="/userProgram"
              element={
                <Layout>
                  <UserProgram />
                </Layout>
              }
            />
            <Route
              path="/members"
              element={
                <Layout>
                  <Member />
                </Layout>
              }
            />
            <Route
              path="/executing-entities"
              element={
                <Layout>
                  <ExecutingEntities />
                </Layout>
              }
            />
            {
              program?.map((item) =>
              (<Route
                path={`/${item.programas.url}`}
                element={<Layout>
                  <Projects 
                  idProgram={item.id_programas}
                  program={item.programas.nombre}
                  />
                </Layout>}
              />)
              )
            }
            <Route
              path="/proyecto/:id"
              element={
                <Layout>
                  <InfoProjects
                  />
                </Layout>
              }
            />
            <Route
              path="/finance-proyecto/:id"
              element={
                <Layout>
                  <FinanceProjects
                  />
                </Layout>
              }
            />
            {/* <Route path="/parameters" exact element={<Layout><Parameters/></Layout>}  />*/}
            <Route path="/parameters/create" element={<Layout><FormParameters /></Layout>} />
            <Route path="/parameters/edit/:id" element={<Layout><FormParameters /></Layout>} />
            <Route
              path="/members-projects/:id"
              element={
                <Layout>
                  <MemberProject />
                </Layout>
              }
            />
            <Route
              path="/actividad/:id"
              element={
                <Layout>
                  <Activities />
                </Layout>
              }
            />
            <Route
              path="/userGroups"
              element={
                <Layout>
                  <UserGroup />
                </Layout>
              }
            />
            <Route
              path="/modules"
              element={
                <Layout>
                  <Module />
                </Layout>
              }
            />
            <Route
              path="/sessions"
              element={
                <Layout>
                  <Session />
                </Layout>
              }
            />
            <Route
              path="/Permissions"
              element={
                <Layout>
                  <Permissions />
                </Layout>
              }
            />
            <Route
              path="/log"
              element={
                <Layout>
                  <Logs />
                </Layout>
              }
            />
            <Route
              path="/profile/edit"
              element={
                <Layout>
                  <ProfileSettings />
                </Layout>
              }
            />
            <Route
              path="*"
              element={
                <NotFound />
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    program: state?.user?.usuariosProgramas,
  };
};

const mapDispatchToprops = {

};

export default connect(mapStateToProps, mapDispatchToprops)(App);

