import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";
import { useNavigate } from "react-router-dom";

const ModalProject = ({
  show,
  setShow,
  token,
  editMode,
  editId,
  get,
  setLoading,
  vp_program,
  loginRequest
}) => {
  const [nombre, setNombre] = React.useState("");
  const [fechaInicio, setFechaInicio] = React.useState("");
  const [fechaFinalizacion, setFechaFinalizacion] = React.useState("");
  const [alcance, setAlcance] = React.useState("");
  const [objetivo, setObjetivo] = React.useState("");
  const [idEntidadEjecutora, setIdEntidadEjecutora] = React.useState("");
  const [idProgram, setIdProgram] = React.useState("");
  const [program, setProgram] = React.useState([]);
  const [excutingEntities, setExcutingEntities] = React.useState([]);
  const [validated, setValidated] = React.useState(false);

  const history = useNavigate();
  const clearFields = () => {
    setNombre("");
    setFechaInicio("");
    setFechaFinalizacion("");
    setAlcance("");
    setObjetivo("");
    setIdEntidadEjecutora("");
    setIdProgram("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (editMode) {
        editUser();
      } else {
        addUser();
      }
    }
  };

  const editUser = () => {
    const fecha_inicio = fechaInicio+" 07:00:00";
    const fecha_fin = fechaFinalizacion+" 07:00:00";
    const fecha_inicio_esperado = fecha_inicio;
    const fecha_fin_esperado = fecha_fin;
    const vp_programa = idProgram;
    const id_entidad_ejecutora = idEntidadEjecutora;
    const objetivo_general= objetivo;
    const newProjects = {
      nombre,
      fecha_inicio,
      fecha_fin,
      fecha_inicio_esperado,
      fecha_fin_esperado,
      alcance,
      vp_programa,
      id_entidad_ejecutora,
      objetivo_general
    };
    handleClose();
    setLoading(true);
    axios
      .put(
        `/project/${editId}`,
        { ...newProjects },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(async () => {
          setLoading(false);
          if (res.data.updated) {
            clearFields();
            get();
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userUpdated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
      });
  };

  const addUser = () => {
    const fecha_inicio = fechaInicio+" 12:00:00";
    const fecha_fin = fechaFinalizacion+" 12:00:00";
    const fecha_inicio_esperado =fecha_inicio;
    const fecha_fin_esperado = fecha_fin;
    const vp_programa = vp_program;
    const id_entidad_ejecutora = idEntidadEjecutora;
    const objetivo_general= objetivo;
    const newProjects = {
      nombre,
      fecha_inicio,
      fecha_fin,
      fecha_inicio_esperado,
      fecha_fin_esperado,
      alcance,
      vp_programa,
      id_entidad_ejecutora,
      objetivo_general
    };
    handleClose();
    setLoading(true);
    axios
      .post(
        `/project/`,
        { ...newProjects },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          if (res.data.project) {
            clearFields();
            get();
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userCreated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
        console.error(error);
      });
  };

  const getExecutingEntities = async () => {
    try {
      const { data } = await axios.post(
        `/executingEntity/getExecutingEntities`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      setExcutingEntities(data.executingEntities);
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response.data.mensaje === "Invalid Token") {
        Swal.fire({
          icon: "error",
          text: "Error al validar usuario.",
          showConfirmButton: false,
          timer: 3000,
        });
        loginRequest();
        history("/");
      } else {
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar las entidades ejecutoras.",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await axios.get(`/project/${editId}`, {
        headers: { "access-token": token },
      });
      setNombre(data.project.nombre);
      setFechaInicio(data.project.fecha_inicio.replace(' 07:00:00',''));      
      setFechaFinalizacion(data.project.fecha_fin.replace(' 07:00:00',''));
      setAlcance(data.project.alcance);
      setObjetivo(data.project.objetivo_general);
      setIdEntidadEjecutora(data.project.id_entidad_ejecutora);
      setIdProgram(data.project.vp_programa);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getProgram = async () => {
    setLoading(true);
    await axios
      .post(
        "/parameter/getParameters",
        { id: 7, },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setProgram(data?.parameters[0]?.valoresParametros)
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Error al mostrar los programas.",
          showConfirmButton: false,
          timer: 3000,
        });
        /* history("/"); */
      });
  };

  React.useEffect(() => {
    getExecutingEntities();
  }, []);

  React.useEffect(() => {
    if (editId !== 0 && editId !== undefined) {
      getProjects();
      getProgram()
    }
  }, [editId]);

  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? "Editar Proyecto" : "Información del Proyecto"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <FloatingLabel
                controlId="nombre"
                label="Nombre Proyecto"
                className="mb"
                md="12"
              >
                <Form.Control
                  md="12"
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="razonSocial"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2"></Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha inicial" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value)
                  }}
                  placeholder="Fecha inicial"
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha Final" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaFinalizacion}
                  onChange={(e) => setFechaFinalizacion(e.target.value)}
                  placeholder="Fecha Final"
                  min={fechaInicio}
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom05">
              <FloatingLabel
                controlId="alcance"
                label="Alcance del Proyecto"
                className="mb"
              >
                <Form.Control
                  as="textarea"
                  value={alcance}
                  onChange={(e) => {
                    setAlcance(e.target.value);
                  }}
                  style={{ height: "100px" }}
                  placeholder="nombre"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom05">
              <FloatingLabel
                controlId="objetivo"
                label="Objetivo General"
                className="mb"
              >
                <Form.Control
                  as="textarea"
                  value={objetivo}
                  onChange={(e) => {
                    setObjetivo(e.target.value);
                  }}
                  style={{ height: "100px" }}
                  placeholder="nombre"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom08">
              <FloatingLabel
                controlId="grupUser"
                label="Entidad Ejecutora"
                className="mb"
              >
                <Form.Select
                  onChange={(e) => {
                    setIdEntidadEjecutora(e.target.value);
                  }}
                  value={idEntidadEjecutora}
                  required
                >
                  <option defaultValue></option>
                  {excutingEntities
                    .sort((a, b) => (a.razon_social < b.razon_social ? -1 : 1))
                    .map((data) => {
                      return (
                        <option key={`group-${data.id}`} value={data.id}>
                          {data.razon_social}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              </FloatingLabel>
            </Form.Group>
          </Row>
          {editMode ? (
            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustom08">
                <FloatingLabel
                  controlId="grupUser"
                  label="Programa"
                  className="mb"
                >
                  <Form.Select
                    onChange={(e) => {
                      setIdProgram(e.target.value);
                    }}
                    value={idProgram}
                    required
                  >
                    <option defaultValue></option>
                    {
                      program?.map(
                        (item)=>(
                          <option value={item.id}>{item.valor_parametro}</option>
                        )
                      )
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    El campo es obligatorio.
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </FloatingLabel>
              </Form.Group>
            </Row>
          ) : null}
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalProject;
