import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { DocumentsGrid } from "../../../data/dummy";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";
import { deleteDocument } from "../../../data/api";

const ModalListDocuments = ({
  show,
  setShow,
  token,
  data,
  setLoading,
  supervisor,
  setSupervisor,
  permission,
  documentTecnico = false,
}) => {
  const [tipoDocument, setTipoDocument] = React.useState("");
  const [categoriaDocumento, setCategoriaDocumento] = React.useState("");
  const [periodo, setPeriodo] = React.useState("");
  const [categorias, setCategorias] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [validated, setValidated] = React.useState(false);
  const ref = React.useRef(null);
  const periodos = [
    { value: 1, name: "Primer Periodo" },
    { value: 2, name: "Segundo Periodo" },
    { value: 3, name: "Tercer Periodo" },
    { value: 4, name: "Cuarto Periodo" },
    { value: 5, name: "Quinto Periodo" },
    { value: 6, name: "Sexto Periodo" },
  ];

  const clearFields = () => {
    setCategorias([]);
    setCategoriaDocumento("");
    setTipoDocument("");
    setPeriodo("");
    setDocuments([]);
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setSupervisor(false);
    clearFields();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      let url;
      let where = { id_proyectos: data, periodo };
      if (tipoDocument == 1) {
        url = "/technicalSupport/gettechnicalSupports";
        where = {
          ...where,
          vp_tipo_soporte_tecnico: parseInt(categoriaDocumento),
        };
      }
      if (tipoDocument == 2) {
        url = "/financialSupport/getfinancialSupports";
        where = {
          ...where,
          vp_tipo_soporte_financieros: parseInt(categoriaDocumento),
        };
      }
      if (tipoDocument == 3) {
        url = "/administrativeSupport/getadministrativeSupports";
        where = {
          ...where,
          vp_tipo_soporte_administrativo: parseInt(categoriaDocumento),
        };
      }
      if (tipoDocument == 4) {
        url = "/changeControl/getchangeControls";
        where = {
          ...where,
          vp_tipo_soporte_control_cambio: parseInt(categoriaDocumento),
        };
      }
      if (tipoDocument === "") {
        url = "/supervisorSupport/getsupervisorSupports";
        where = {
          ...where,
          vp_tipo_soporte_supervisor: documentTecnico ? 1 : 2,
        };
      }
      getDocuments(url, where);
    }
  };

  const getDocuments = async (url, where) => {
    setLoading(true);
    await axios
      .post(url, where, {
        headers: {
          "access-token": token,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        if (data?.documents.length < 1) {
          Swal.fire({
            icon: "info",
            text: "No hay Documentos agregados.",
            showConfirmButton: false,
            timer: 3000,
          });
          handleClose();
        } else {
          const documentsResult =
            data?.documents.map((item) => ({
              ...item,
              deleteDocuments,
            })) || [];
          setDocuments(documentsResult);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los Docuementos.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const deleteDocuments = async (id) => {
    let path = "";
    let url = "";
    let where = { id_proyectos: data };
    switch (tipoDocument) {
      case "1":
        path = "/technicalSupport/";
        url = "/technicalSupport/gettechnicalSupports";
        where = {
          ...where,
          vp_tipo_soporte_tecnico: parseInt(categoriaDocumento),
        };
        break;
      case "2":
        path = "/financialSupport/";
        url = "/financialSupport/getfinancialSupports";
        where = {
          ...where,
          vp_tipo_soporte_financieros: parseInt(categoriaDocumento),
        };
        break;
      case "3":
        path = "/administrativeSupport/";
        url = "/administrativeSupport/getadministrativeSupports";
        where = {
          ...where,
          vp_tipo_soporte_administrativo: parseInt(categoriaDocumento),
        };
        break;
      case "4":
        path = "/changeControl/";
        url = "/changeControl/getchangeControls";
        where = {
          ...where,
          vp_tipo_soporte_control_cambio: parseInt(categoriaDocumento),
        };
        break;
      default:
        path = "/supervisorSupport/";
        url = "/supervisorSupport/getsupervisorSupports";
        where = {
          ...where,
          vp_tipo_soporte_supervisor: parseInt(categoriaDocumento),
        };
        break;
    }
    Swal.fire({
      text: `¿Está seguro que desea eliminar este archivo?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        deleteDocument(path, id, token, setLoading, getDocuments(url, where)); 
      }
    });
  };

  const document = () => {
    const tecnico = [
      {
        value: 1,
        categoria: "Evidencias de ejecución",
      },
      {
        value: 2,
        categoria: "Informe tecnico",
      },
      {
        value: 3,
        categoria: "Acta de reunión",
      },
    ];
    const financiero = [
      {
        value: 1,
        categoria: "Informe Financiero",
      },
      {
        value: 2,
        categoria: "Factura",
      },
      {
        value: 3,
        categoria: "Comprobante",
      },
    ];
    const administrativo = [
      {
        value: 1,
        categoria: "Contratos",
      },
      {
        value: 2,
        categoria: "Polizas",
      },
      {
        value: 3,
        categoria: "Orden de servicio",
      },
    ];
    const cambio = [
      {
        value: 1,
        categoria: "Solicitud de modificación",
      },
      {
        value: 2,
        categoria: "Acta de aceptación de cambios",
      },
    ];

    if (tipoDocument == 1) {
      setCategorias(tecnico);
    }
    if (tipoDocument == 2) {
      setCategorias(financiero);
    }
    if (tipoDocument == 3) {
      setCategorias(administrativo);
    }
    if (tipoDocument == 4) {
      setCategorias(cambio);
    }
  };
  const download = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.substr(url?.lastIndexOf("=") + 1));
      });
  };

  const toolbarOptions = ["Search"];

  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  React.useEffect(() => {
    document();
  }, [tipoDocument]);
  React.useEffect(() => {
    /* 
    if (supervisor) {
      let url = "/supervisorSupport/getsupervisorSupports";
      let where = {
        id_proyectos: data,
        vp_tipo_soporte_supervisor: documentTecnico ? 1 : 2,
      };
      getDocuments(url, where);
    } */
  }, [show]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title> Documentos</Modal.Title>
        <div
          className="icon"
          style={{
            position: "absolute",
            right: "20px",
          }}
        >
          <box-icon
            name="x"
            onClick={() => {
              handleClose();
              setSupervisor(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {supervisor ? null : (
            <>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="validationCustom08">
                  <FloatingLabel
                    controlId="grupUser"
                    label="Tipos documento"
                    className="mb"
                  >
                    <Form.Select
                      onChange={(e) => {
                        setTipoDocument(e.target.value);
                      }}
                      value={tipoDocument}
                      required
                    >
                      <option defaultValue></option>
                      {documentTecnico ? (
                        <>
                          <option value={1}>Soportes Tecnicos</option>
                          <option value={4}>Control de cambio</option>
                        </>
                      ) : (
                        <>
                          <option value={2}>Soportes Financieros</option>
                          <option value={3}>Soportes Administrativos</option>
                        </>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      El campo es obligatorio.
                    </Form.Control.Feedback>
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="validationCustom08">
                  <FloatingLabel
                    controlId="grupUser"
                    label="Categoria"
                    className="mb"
                  >
                    <Form.Select
                      onChange={(e) => {
                        setCategoriaDocumento(e.target.value);
                      }}
                      value={categoriaDocumento}
                      required
                    >
                      <option defaultValue></option>
                      {supervisor ? (
                        <>
                          <option value={1}>Informe Financiero</option>
                          <option value={2}>Informe Tecnico</option>
                        </>
                      ) : (
                        categorias.map((item, index) => (
                          <option value={item.value} key={index}>
                            {item.categoria}
                          </option>
                        ))
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      El campo es obligatorio.
                    </Form.Control.Feedback>
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </>
          )}
          <Row className="mb-2">
            <Form.Group as={Col}>
              <FloatingLabel controlId="periodo" label="Periodo" className="mb">
                <Form.Select
                  onChange={(e) => {
                    setPeriodo(e.target.value);
                  }}
                  value={periodo}
                  required
                >
                  <option defaultValue></option>
                  {periodos.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-success"
              type="submit"
              id="search"
              ref={ref}
            >
              Buscar
            </Button>
          </Modal.Footer>
        </Form>

        <Row className="mb-2">
          <Modal.Title>
            {documents.length > 0 ? "Lista de Documentos" : ""}
          </Modal.Title>
        </Row>
        {documents.length > 0 ? (
          <GridComponent
            dataSource={documents}
            enableHover={false}
            allowPaging
            searchSettings={searchOptions}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 5 }}
            allowSorting
          >
            <ColumnsDirective>
              {DocumentsGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
          </GridComponent>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ModalListDocuments;
