import axios from "../api";
import Swal from "sweetalert2";

// Agregar
export const addObjective = async (info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .post(
      `/objective/`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
        if (res.data?.objective) {
          get();
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.objective.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

export const addExpense = async (info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .post(
      `/expense/`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
        if (res.data?.expense) {
          get();
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.objective.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

export const addMembersProjects = async (
  info,
  token,
  setLoading,
  get,
  setShow,
  url
) => {
  setLoading(true);
  axios
    .post(
      url,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then(async (res) => {
      setLoading(false);
      if (res.data?.memberProject) {
        if (res.data.memberProject === -1) {
          Swal.fire({
            icon: "error",
            text: "El miembro ya existe.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          get();
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } else {
        setShow(true);
        Swal.fire({
          icon: "error",
          text: res.data.objective.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

export const addDocuments = async (
  info,
  token,
  setLoading,
  get,
  setShow,
  url,
  file
) => {
  setLoading(true);
  axios
    .post(
      url,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then(async (res) => {
      setLoading(false);
      if (res.data?.document) {
        const formFile = new FormData();
        formFile.append("file", file);
        let id = res.data.document;
        let carpeta;
        let folder;
        let ext = file.name.substr(file.name.indexOf(".") + 1);
        if (info?.vp_tipo_soporte_administrativo) {
          folder = "Soporte_Administrativo";
          carpeta =
            info?.vp_tipo_soporte_administrativo == 1
              ? "contratos"
              : info?.vp_tipo_soporte_administrativo == 2
                ? "Polizas"
                : "Orden de Servicio";
        }
        if (info?.vp_tipo_soporte_financieros) {
          folder = "Soporte_Financiero";
          carpeta =
            info?.vp_tipo_soporte_financieros == 1
              ? "Informe Financiero"
              : info?.vp_tipo_soporte_financieros == 2
                ? "Factura"
                : info?.vp_tipo_soporte_financieros == 3
                  ? "Comprobante"
                  : "Presupuesto Proyecto";
        }
        if (info?.vp_tipo_soporte_tecnico) {
          folder = "Soporte_Tecnico";
          carpeta =
            info?.vp_tipo_soporte_tecnico == 1
              ? "Evidencia_Ejecucion"
              : info?.vp_tipo_soporte_tecnico == 2
                ? "Informe_Tecnico"
                : "Acta_Reunion";
        }
        if (info?.vp_tipo_soporte_control_cambio) {
          folder = "Control_De_Cambios";
          carpeta =
            info?.vp_tipo_soporte_control_cambio == 1
              ? "Solicitud_Modificacion"
              : "Actas_Aceptacion_cambios";
        }
        if (info?.vp_tipo_soporte_supervisor) {
          folder = "Documentos_Supervisor";
          carpeta =
            info?.vp_tipo_soporte_supervisor == 1
              ? "Informe_Financiero"
              : "Informe_Tecnico";
        }
        if (info?.vp_tipo_soporte_presupuesto) {
          folder = "Excel_Presupuestos";
          carpeta =
            info?.vp_tipo_soporte_presupuesto == 1
              ? "Presupuesto_Proyecto"
              : "Presupuesto_MesualProyecto";
        }
        let ruta = await axios
          .post(
            `/file/subfolder/${folder}/${carpeta}/${info?.nombre}/${ext}/${id}`,
            formFile,
            {
              headers: { "acces-token": token },
            }
          )
          .then((res) => {
            if (res.data.success) return res.data.ruta;
          });
        axios
          .put(
            `${url}${id}`,
            { ruta },
            {
              headers: { "access-token": token },
            }
          )
          .then((res) => {
            if (res.data?.updated) {
              get();
              Swal.fire({
                icon: "success",
                text: "Creado exitosamente.",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      } else {
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "Error al cargar el documento",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};
export const addPresupuestoProyectoExcel = async (
  excel,
  token,
  setLoading,
  get,
  setShow,
  idProject,
  file,
  nombre
) => {
  setLoading(true);
  axios
    .post(
      `/expense/excel`,
      { excel, idProject },
      {
        headers: { "access-token": token },
      }
    )
    .then(async (res) => {
      setTimeout(() => {
        if (res.data?.expense) {
          let info = {
            nombre,
            id_proyectos: idProject,
            ruta: "",
            vp_tipo_soporte_presupuesto: 1,
          };
          addDocuments(
            info,
            token,
            setLoading,
            get,
            setShow,
            "/budgetSupport/",
            file
          );
          setShow(false);
          /* get();
          setShow(false);
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          }); */
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.expense.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};
export const EditPresupuestoMensualProyecto = async (
  excel,
  token,
  setLoading,
  get,
  setShow,
  idProject,
  date,
  file,
  nombre
) => {
  setLoading(true);
  axios
    .put(
      "/expense/addvalue/",
      { excel, idProject, date },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setLoading(false);
      if (res.data?.updated) {
        let info = {
          nombre,
          id_proyectos: idProject,
          ruta: "",
          vp_tipo_soporte_presupuesto: 2,
          fecha: date,
        };
        addDocuments(
          info,
          token,
          setLoading,
          get,
          setShow,
          "/budgetSupport/",
          file
        );
        setShow(false);
      } else {
        setShow(true);
        Swal.fire({
          icon: "error",
          text: res.data.objective.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

const uploadFile = async (id, token, get, file, message) => {
  if (file !== "") {
    const formFile = new FormData();
    formFile.append("file", file);
    let ruta = await axios
      .post(`/file/archivo_entregable/${id}`, formFile, {
        headers: { "acces-token": token },
      })
      .then((res) => {
        if (res.data.success) return res.data.ruta;
      });
    await axios.put(
      `/deliverable/${id}`,
      { ruta },
      {
        headers: { "access-token": token },
      }
    );
  }
  get();
  Swal.fire({
    icon: "success",
    text: message,
    showConfirmButton: false,
    timer: 3000,
  });
};
export const uploadFileEjecutor = async (
  id,
  token,
  setLoading,
  get,
  setShow,
  file
) => {
  setLoading(true)
  await uploadFile(id, token, get, file, "Cargue de archivo Exitoso")
  setLoading(false)
  setShow(false)
}

export const addDeliverable = async (
  info,
  token,
  setLoading,
  get,
  setShow,
  file
) => {
  setLoading(true);
  axios
    .post(
      `/deliverable/`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then(async (res) => {
      setLoading(false);
      if (res.data?.deliverable) {
        uploadFile(res.data.deliverable, token, get, file, "Creado exitosamente.")
        setShow(false)
        /* if (file !== "") {
          const formFile = new FormData();
          formFile.append("file", file);
          let id = res.data.deliverable;
          let ruta = await axios
            .post(`/file/archivo_entregable/${id}`, formFile, {
              headers: { "acces-token": token },
            })
            .then((res) => {
              if (res.data.success) return res.data.ruta;
            });
          axios.put(
            `/deliverable/${id}`,
            { ruta },
            {
              headers: { "access-token": token },
            }
          );
        }
        get();
        Swal.fire({
          icon: "success",
          text: "Creado exitosamente.",
          showConfirmButton: false,
          timer: 3000,
        }); */
      } else {
        setShow(true);
        Swal.fire({
          icon: "error",
          text: res.data.objective.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

export const addIndicators = async (info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .post(
      `/indicator/`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
        if (res.data?.indicator) {
          get();
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.objective.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};
export const addPrograms = async (info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .post(
      `/program/`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
        if (res.data?.program) {
          get();
          Swal.fire({
            icon: "success",
            text: "Creado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.objective.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};
export const editPrograms = async (id,info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .put(
      `/program/${id}`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
        if (res.data?.updated) {
          get();
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.objective.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido crear.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
      console.error(error);
    });
};

// Editar

export const editActivity = (id, info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .put(
      `/activity/${id}`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(async () => {
        setLoading(false);
        if (res.data.updated) {
          get();
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.userUpdated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
    });
};
export const editIndicator = (id, info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .put(
      `/indicator/${id}`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(async () => {
        setLoading(false);
        if (res.data.updated) {
          get();
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.userUpdated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
    });
};

export const editObjective = (id, info, token, setLoading, get, setShow) => {
  setLoading(true);
  axios
    .put(
      `/objective/${id}`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(async () => {
        setLoading(false);
        if (res.data.updated) {
          get();
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.userUpdated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
    });
};

export const editDeliverable = (
  id,
  info,
  token,
  setLoading,
  get,
  setShow,
  file
) => {
  setLoading(true);
  axios
    .put(
      `/deliverable/${id}`,
      { ...info },
      {
        headers: { "access-token": token },
      }
    )
    .then((res) => {
      setTimeout(async () => {
        setLoading(false);
        if (res.data.updated) {
          get();
          Swal.fire({
            icon: "success",
            text: "Editado exitosamente.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          setShow(true);
          Swal.fire({
            icon: "error",
            text: res.data.userUpdated.message,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }, 2000);
    })
    .catch((error) => {
      console.error(error);
      setTimeout(() => {
        setLoading(false);
        setShow(true);
        Swal.fire({
          icon: "error",
          text: "No se ha podido editar.",
          showConfirmButton: false,
          timer: 3000,
        });
      }, 2000);
    });
};

// Eliminar

export const deleteObjective = async (id, token, setLoading, get) => {
  await axios
    .delete(`/objective/${id}`, {
      headers: { "access-token": token },
    })
    .then(({ data }) => {
      get();
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};
export const deletePresupuesto = async (id, token, setLoading, get) => {
  await axios
    .delete(`/expense/${id}`, {
      headers: { "access-token": token },
    })
    .then(async ({ data }) => {
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        await axios.delete(`/budgetSupport/excel/${id}`, {
          headers: { "access-token": token },
        });
        get();
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

export const deleteActivity = async (id, token, setLoading, get) => {
  await axios
    .delete(`/activity/${id}`, {
      headers: { "access-token": token },
    })
    .then(({ data }) => {
      get();
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

export const deleteDeliverable = async (id, token, setLoading, get) => {
  await axios
    .delete(`/deliverable/${id}`, {
      headers: { "access-token": token },
    })
    .then(({ data }) => {
      get();
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};
export const deleteDocument = async (path, id, token, setLoading, get) => {
  await axios
    .delete(`${path}${id}`, {
      headers: {
        "access-token": token,
      },
    })
    .then(({ data }) => {
      get();
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          text: "Eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};
export const deleteUsersProjects = async (id, token, setLoading, get) => {
  await axios
    .delete(`/userProject/${id}`, {
      headers: { "access-token": token },
    })
    .then(({ data }) => {
      get();
      if (!data.deleted) {
        Swal.fire({
          text: "A ocurrido un error",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        Swal.fire({
          title: "Eliminado!",
          // 'Your file has been deleted.',
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};
