import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";
import { addMembersProjects } from "../../../data/api.js";
const ModalMembers = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
  supervisor,
}) => {
  const [tipoMember, setTipoMember] = React.useState("");
  const [categoriaMember, setCategoriaMember] = React.useState("");
  const [responsibility, setResponsibility] = React.useState("");
  const [porcentaje, setPorcentaje] = React.useState(0);
  const [categorias, setCategorias] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [validated, setValidated] = React.useState(false);
  const [fechaInicio, setFechaInicio] = React.useState("");
  const [fechaFinalizacion, setFechaFinalizacion] = React.useState("");

  const clearFields = () => {
    setTipoMember("");
    setResponsibility("");
    setPorcentaje(0);
    setCategoriaMember("");
    setFechaInicio("");
    setFechaFinalizacion("");
    setCategorias([]);
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    clearFields();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      let info = {
        id_proyectos: data,
      };
      let url;
      if (tipoMember == 1||tipoMember == 3||tipoMember == 4) {
        url = "/userProject/";
        info = {
          ...info,
          id_usuarios: categoriaMember,
        };
      }
      if (tipoMember == 2) {
        url = "/memberProject/";
        info = {
          ...info,
          id_miembros: categoriaMember,
          responsabilidad:responsibility,
          dedicacion:porcentaje,
          fecha_inicio:fechaInicio,
          fecha_fin:fechaFinalizacion
        };
      }
      addMembersProjects(info, token, setLoading, get, setShow, url);
      handleClose();
    }
  };

  const document = () => {
    if (tipoMember == 1) {
      setCategorias(users.filter(
        (item) => item.id_grupos_usuarios === 2
      ));
    }
    if (tipoMember == 3) {
      setCategorias(users.filter(
        (item) => item.id_grupos_usuarios === 3
      ));
    }
    if (tipoMember == 4) {
      setCategorias(users.filter(
        (item) => item.id_grupos_usuarios === 7
      ));
    }
    if (tipoMember == 2) {
      setCategorias(members);
    }
  };

  React.useEffect(() => {
    document();
  }, [tipoMember]);
  React.useEffect(() => {
    getUsers();
    getMembers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    await axios
      .post(
        "/user/getUsers",
        {},
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        const usersFiltered = data?.users.filter(
          (item) => item.id_grupos_usuarios !=5 && item.id_grupos_usuarios !=6
        );
        setUsers(usersFiltered);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los usuarios.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const getMembers = async () => {
    setLoading(true);
    await axios
      .post(
        "/member/getmembers",
        {},
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setMembers(data.members);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los usuarios.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Agregar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {supervisor ? null : (
            <Row className="mb-2">
              <Form.Group as={Col} controlId="validationCustom08">
                <FloatingLabel
                  controlId="grupUser"
                  label="Tipos miembro"
                  className="mb"
                >
                  <Form.Select
                    onChange={(e) => {
                      setTipoMember(e.target.value);
                    }}
                    value={tipoMember}
                    required
                  >
                    <option defaultValue></option>
                    <option value={1}>Lider del Proyecto</option>
                    <option value={3}>Supervisor</option>
                    <option value={4}>Ejecutor</option>
                    <option value={2}>Equipo de trabajo</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    El campo es obligatorio.
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                </FloatingLabel>
              </Form.Group>
            </Row>
          )}
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom08">
              <FloatingLabel
                controlId="grupUser"
                label="miembro"
                className="mb"
              >
                <Form.Select
                  onChange={(e) => {
                    setCategoriaMember(e.target.value);
                  }}
                  value={categoriaMember}
                  required
                >
                  <option defaultValue></option>
                  {categorias.map((item) => (
                    <option
                      value={item.id}
                    >{`${item.nombres} ${item.apellidos}`}</option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              </FloatingLabel>
            </Form.Group>
          </Row>
          {tipoMember==2?
          <>
          <Row className="mb-2">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <FloatingLabel
                controlId="responsabilidades"
                label="Responsabilidades"
                className="mb"
              >
                <Form.Control
                  md="6"
                  type="text"
                  value={responsibility}
                  onChange={(e) => {
                    setResponsibility(e.target.value);
                  }}
                  /* placeholder="Responsabilidades" */
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Los nombre son obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>          
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha inicial" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value)
                  }}
                  placeholder="Fecha inicial"
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel controlId="startDate" label="Fecha Final" md="6">
                <Form.Control
                  md="6"
                  type="date"
                  value={fechaFinalizacion}
                  onChange={(e) => setFechaFinalizacion(e.target.value)}
                  placeholder="Fecha Final"
                  min={fechaInicio}
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} controlId="validationCustom03">
              Dedicación: {porcentaje}%
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <Form.Range
                value={porcentaje}
                onChange={(e) => {
                  setPorcentaje(e.target.value);
                }}
                placeholder="porcentaje"
                required
              />
            </Form.Group>
          </Row>
          </>
          :null}
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn rounded"
              variant="outline-success"
              type="submit"
            >
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMembers;
