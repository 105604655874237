import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import axios from "../../../api";

const ModalInfoUser = ({
  show,
  setShow,
  token,
  editMode,
  editId,
  groupId,
  userId,
  getUsers,
  setLoading,
}) => {
  const [userGroup, setUserGroup] = React.useState([]);
  const [nombres, setNombres] = React.useState("");
  const [apellidos, setApellidos] = React.useState("");
  const [documento, setDocumento] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [idGruposUsuarios, setIdGruposUsuarios] = React.useState(0);
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setNombres("");
    setApellidos("");
    setTelefono("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setDocumento("");
    setIdGruposUsuarios(0);
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (editMode) {
        editUser();
      } else {
        addUser();
      }
    }
  };

  const editUser = () => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        text: "Las contraseñas no coinciden.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (password!=='') {
      if (password.length < 6) {
        Swal.fire({
          icon: "error",
          text: "la contraseña debe terner al menos 6 caracteres.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
    }
    const newUser = {
      nombres,
      apellidos,
      documento,
      telefono,
      email,
      password: password !== "" ? password : null,
      confirmPassword: confirmPassword !== "" ? confirmPassword : null,
      id_grupos_usuarios: idGruposUsuarios,
    };

    handleClose();
    setLoading(true);
    axios
      .put(
        `/user/${editId}`,
        { ...newUser, userId },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(async () => {
          setLoading(false);
          if (res.data.userUpdated.success) {
            clearFields();
            getUsers();
            Swal.fire({
              icon: "success",
              text: "Editado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userUpdated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido editar.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
      });
  };

  const addUser = () => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "error",
        text: "Las contraseñas no coinciden.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (password.length < 6) {
      Swal.fire({
        icon: "error",
        text: "la contraseña debe terner al menos 6 caracteres.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    const newUser = {
      nombres,
      apellidos,
      documento,
      telefono,
      email,
      password,
      confirmPassword,
      id_grupos_usuarios: idGruposUsuarios,
    };
    handleClose();
    setLoading(true);
    axios
      .post(
        `/user/`,
        { ...newUser, userId },
        {
          headers: { "access-token": token },
        }
      )
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          if (res.data.userCreated.success) {
            clearFields();
            getUsers();
            Swal.fire({
              icon: "success",
              text: "Creado exitosamente.",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            setShow(true);
            Swal.fire({
              icon: "error",
              text: res.data.userCreated.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }, 2000);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoading(false);
          setShow(true);
          Swal.fire({
            icon: "error",
            text: "No se ha podido crear.",
            showConfirmButton: false,
            timer: 3000,
          });
        }, 2000);
        console.error(error);
      });
  };

  const getUser = async () => {
    try {
      // si ya se tienen los datos en la tabal por que no pasa el objeto aca directamente en vez de consultaro asi no realizas tanta peticones al servidor
      const { data } = await axios.get(`/user/${editId}`, {
        headers: { "access-token": token },
      });
      setNombres(data.user?.nombres);
      setApellidos(data.user?.apellidos);
      setDocumento(data.user?.documento);
      setTelefono(data.user?.telefono);
      setEmail(data.user?.email);
      setIdGruposUsuarios(data.user?.id_grupos_usuarios);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getUserGroups = async () => {
    try {
      const { data } = await axios.post(
        `/userGroup/getGroups`,
        {},
        {
          headers: { "access-token": token },
        }
      );
      if (groupId === 6) {
        setUserGroup(data.groups);
      } else {
        setUserGroup(data.groups.filter((item) => item.id !== 6));
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Ocurrio un error al consultar los grupos de trabajo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const soloNumeros = (e) => {
    const key = e.charCode;
    if (!(key >= 48 && key <= 57)) {
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    getUserGroups();
  }, []);

  React.useEffect(() => {
    if (editId !== 0 && editId !== undefined) {
      getUser();
    }
  }, [editId]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Editar Usuario" : "Crear Usuario"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <FloatingLabel
                controlId="nombres"
                label="Nombres"
                className="mb"
                md="6"
              >
                <Form.Control
                  md="6"
                  type="text"
                  value={nombres}
                  onChange={(e) => {
                    setNombres(e.target.value);
                  }}
                  placeholder="Nombres"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Los nombre son obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <FloatingLabel
                controlId="apellidos"
                label="Apellidos"
                className="mb"
                md="6"
              >
                <Form.Control
                  md="6"
                  type="text"
                  value={apellidos}
                  onChange={(e) => {
                    setApellidos(e.target.value);
                  }}
                  placeholder="Apellidos"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Los Apellidos Son Obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <FloatingLabel
                controlId="documento"
                label="Documento"
                className="mb"
                md="6"
              >
                <Form.Control
                  md="6"
                  type="text"
                  value={documento}
                  onChange={(e) => {
                    setDocumento(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    soloNumeros(e);
                  }}
                  placeholder="Documento"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El Documento es Obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
              <FloatingLabel
                controlId="telefono"
                label="Telefono"
                className="mb"
                md="6"
              >
                <Form.Control
                  md="6"
                  type="text"
                  value={telefono}
                  onChange={(e) => {
                    setTelefono(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    soloNumeros(e);
                  }}
                  placeholder="Telefono"
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom05">
              <FloatingLabel
                controlId="email"
                label="Correo electrónico"
                className="mb"
              >
                <Form.Control
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Correo electónico"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El correo electronico es obligatorio.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            {editMode ? (
              <Form.Group as={Col} md="6" controlId="validationCustom06">
                <FloatingLabel
                  controlId="contrasena"
                  label="Contraseña"
                  className="mb"
                  md="6"
                >
                  <Form.Control
                    md="6"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Contraseña"
                  />
                  <Form.Control.Feedback type="invalid">
                    La contraseña es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            ) : (
              <Form.Group as={Col} md="6" controlId="validationCustom06">
                <FloatingLabel
                  controlId="contrasena"
                  label="Contraseña"
                  className="mb"
                  md="6"
                >
                  <Form.Control
                    md="6"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    placeholder="Contraseña"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La contraseña es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            )}
            {editMode ? (
              <Form.Group as={Col} md="6" controlId="validationCustom07">
                <FloatingLabel
                  controlId="confirmPassword"
                  label="Confirmar Contraseña"
                  className="mb"
                  md="6"
                >
                  <Form.Control
                    md="6"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    placeholder="Confirmar Contraseña"
                  />
                  <Form.Control.Feedback type="invalid">
                    La contraseña es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            ) : (
              <Form.Group as={Col} md="6" controlId="validationCustom07">
                <FloatingLabel
                  controlId="confirmPassword"
                  label="Confirmar Contraseña"
                  className="mb"
                  md="6"
                >
                  <Form.Control
                    md="6"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    placeholder="Confirmar Contraseña"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    La contraseña es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            )}
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom08">
              <FloatingLabel controlId="grupUser" label="Rol" className="mb">
                <Form.Select
                  onChange={(e) => setIdGruposUsuarios(e.target.value)}
                  value={idGruposUsuarios}
                  placeholder="Grupo de Usuario"
                  required
                >
                  <option defaultValue></option>
                  {userGroup
                    .sort((a, b) => (a.nombre < b.nombre ? -1 : 1))
                    .map((data) => {
                      return (
                        <option key={`group-${data.id}`} value={data.id}>
                          {data.nombre}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  El grupo de usuario es obligatorio.
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoUser;
