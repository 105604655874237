import React from "react";
import { Button, Modal, FloatingLabel, Form, Col, Row } from "react-bootstrap";
import {
  addPrograms,
  editPrograms
} from "../../data/api";

const ModalInfoProject = ({
  show,
  setShow,
  token,
  editMode = false,
  get,
  setLoading,
  data,
}) => {
  const [nombre, setNombre] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const clearFields = () => {
    setNombre("");
  };

  const handleClose = () => {
    setShow(false);
    setValidated(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {        
      let info = {
        nombre: nombre.trim(),
        url: nombre.trim().toLocaleLowerCase().replaceAll(" ","-")
      };
      handleClose();
      if (editMode) {
        editPrograms(data.id,info, token,setLoading, get,setShow)
      } else {
        addPrograms(info,token, setLoading, get,setShow)
      }
      clearFields();
    }
  };

  React.useEffect(() => {
    if (editMode) {
      setNombre(data?.nombre);
    }
  }, [data]);

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          {editMode ? "Editar" : "Crear"} Programa
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="validationCustom03">
              <FloatingLabel controlId="nombre" label="Nombre" className="mb">
                <Form.Control
                  type="text"
                  value={nombre}
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  placeholder="nombre"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  El campo es obligatorio
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
          <Modal.Footer>
            <Button
              className="btn rounded"
              variant="outline-dark"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            {editMode ? (
              <Button
                className="btn rounded"
                variant="outline-warning"
                type="submit"
              >
                Editar
              </Button>
            ) : (
              <Button
                className="btn rounded"
                variant="outline-success"
                type="submit"
              >
                Registrar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInfoProject;
