import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { memberProjectGrid } from "../../data/dummy";
import { Header, Backdrop } from "../../components";
import { decrypt } from "../../utils/crypt.js";
import DeleteUser from "../../components/Modals/membersProjects/DeleteMember";
import ModalInfoUser from "../../components/Modals/membersProjects/ModalMembers";
import Swal from "sweetalert2";
import { Tooltip } from "@material-ui/core";
import { TiDeleteOutline } from "react-icons/ti";
import { deleteUsersProjects } from "../../data/api";

const Member = (props) => {
  const { token, userId, groupId, permissionMembers, permissionUsers } = props;
  const [users, setUsers] = useState([]);
  const [project, setProject] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editId, setEditId] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRegisterUser, setModalResiterUser] = useState(false);
  const [showModalEditUser, setShowModalEditUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();
  const params = useParams();

  const exportExcel = async () => {
    const arrayExcel = await users?.map((item) => {
      const user = {
        Documento: item.documento || "",
        Nombres: item.nombres,
        Apellidos: item.apellidos,
        "Correo electrónico": item.email,
        Teléfono: item.telefono || "",
        "Grupo de usuario": item.grupoUsuarios?.nombre,
      };
      return user;
    });
    await exportToExcel(arrayExcel, "Usuarios");
  };

  const deleteUsersList = () => {
    if (selectedUsers.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const editUsers = () => {
    if (selectedUsers.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selectedUsers.length === 1) {
      setEditId(selectedUsers[0].id);
      setShowModalEditUser(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede selecionar un usuario para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerUser = () => {
    setModalResiterUser(true);
  };

  const viewmembers = () => {
    history("/members");
  };

  const deleteUser = {
    align: "Left",
    click: deleteUsersList,
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: permissionMembers.includes(4),
    width: "auto",
  };


  const addUser = {
    align: "Left",
    click: registerUser,
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: permissionMembers.includes(2),
    width: "auto",
  };

  const EditUser = {
    align: "Left",
    click: editUsers,
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: permissionMembers.includes(3),
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: permissionMembers.includes(5),
    width: "auto",
  };

  const toolbarOptions = [
    "Search",
    addUser,
    /* member,  EditUser,*/
    deleteUser,
    excelExport,
  ];

  const searchOptions = {
    fields: [
      "miembros.nombres",
      "miembros.apellidos",
      "miembros.documento",
      "responsabilidad",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getmemberProjects = async () => {
    setLoading(true);
    await axios
      .post(
        "/memberProject/getmemberProjects",
        { id_proyectos: decrypt(params.id) },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setUsers(data?.memberProjects);
        setEditId(0);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los usuarios.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const getuserProjects = async () => {
    setLoading(true);
    await axios
      .post(
        "/userProject/getuserProjects",
        { id_proyectos: decrypt(params.id) },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setMembers(data?.userProjects);
        setEditId(0);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los usuarios.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const getProject = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`/project/${decrypt(params?.id)}`, {
        headers: { "access-token": token },
      });
      setProject(data.project);
      setLoading(false)
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    if (permissionMembers.includes(1)) {
      getmemberProjects();
      getuserProjects();
      getProject();
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  const get = () => {
    getmemberProjects();
    getuserProjects();
    getProject();
  };

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelectedUsers([...selectedUsers, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter(
          (item) => item.id !== data.id
        );
        setSelectedUsers(selectedUsersFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selectedUsers.includes(item.id));
        setSelectedUsers([...selectedUsers, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter((item) =>
          data.includes(item.id)
        );
        setSelectedUsers(selectedUsersFilter);
      }
    }
  };

  const deleteMember = (member) => {
    Swal.fire({
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      title: `Seguro que desea eliminar a ${member.usuarios.nombres} ${member.usuarios.apellidos}?`,
      showCancelButton: true,
      icon: "warning",
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#666666",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUsersProjects(member.id, token, setLoading, getuserProjects);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelado",
          // 'Your imaginary file is safe :)',
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <DeleteUser
        show={showModalDelete}
        setShow={setShowModalDelete}
        selectedUsers={selectedUsers}
        userId={userId}
        token={token}
        setSelectedUsers={setSelectedUsers}
        getMembers={getmemberProjects}
        setLoading={setLoading}
      />
      <ModalInfoUser
        show={showModalRegisterUser}
        setShow={setModalResiterUser}
        groupId={groupId}
        token={token}
        userId={userId}
        get={get}
        setLoading={setLoading}
        data={decrypt(params.id)}
      />
      <Tooltip title="Atras">
        <box-icon
          name="left-arrow-alt"
          onClick={() =>
            project.vp_programa == 1
              ? history(`/diie-atlantico`)
              : project.vp_programa == 2
              ? history(`/criie-guajira`):
                project.vp_programa==3?
                 history(`/criie-cordoba`)
                 : history(`/diie-videojuegos`)
          }
        />
      </Tooltip>
      <p className="text-3xl font-extrabold tracking-tight text-slate-900">Participantes del proyecto</p>
      <Header
        category={
          project.vp_programa == 1
            ? `Diie Atlantico - ${project?.entidadEjecutora?.razon_social}`
            : project.vp_programa == 2
            ? `Criie Guajira - ${project?.entidadEjecutora?.razon_social}`
            : `Criie Cordoba - ${project?.entidadEjecutora?.razon_social}`
        }
        title={`${project.nombre}`}
      />      
      <br />
      <div className="flex mb-4">
        <div className="mx-4">
          <p className="fw-bold">Lider del Proyecto</p>
          <ul>
            {members?.map((item) =>
              item.usuarios.id_grupos_usuarios == 2 ? (
                <div className="flex">
                  <li
                    style={{ listStyleType: "disc" }}
                    key={item.usuarios.id}
                    className="mx-4"
                  >
                    {`${item.usuarios.nombres} ${item.usuarios.apellidos}`}
                  </li>
                  <TiDeleteOutline
                    size="23px"
                    title="Eliminar Lider del Proyecto"
                    onClick={() => {
                      deleteMember(item);
                    }}
                    style={{
                      cursor: "pointer",
                      display: permissionUsers.includes(4) ? null : "none",
                    }}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
        <div className="mx-4">
          <p className="fw-bold">Supervisor</p>
          <ul>
            {members?.map((item) =>
              item.usuarios.id_grupos_usuarios == 3 ? (
                <div className="flex">
                  <li
                    className="mx-4"
                    style={{ listStyleType: "disc" }}
                    key={item.usuarios.id}
                  >
                    {`${item.usuarios.nombres} ${item.usuarios.apellidos}`}
                  </li>
                  <TiDeleteOutline
                    size="23px"
                    title="Eliminar Supervisor"
                    onClick={() => {
                      deleteMember(item);
                    }}
                    style={{ cursor: "pointer",display: permissionUsers.includes(4) ? null : "none" }}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
        <div className="mx-4">
          <p className="fw-bold">Ejecutor</p>
          <ul>
            {members?.map((item) =>
              item.usuarios.id_grupos_usuarios == 7 ? (
                <div className="flex">
                  <li
                    className="mx-4"
                    style={{ listStyleType: "disc" }}
                    key={item.usuarios.id}
                  >
                    {`${item.usuarios.nombres} ${item.usuarios.apellidos}`}
                  </li>
                  <TiDeleteOutline
                    size="23px"
                    title="Eliminar Ejecutor"
                    onClick={() => {
                      deleteMember(item);
                    }}
                    style={{ cursor: "pointer",display: permissionUsers.includes(4) ? null : "none" }}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </ul>
        </div>
      </div>
      <GridComponent
        dataSource={users}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
      >
        <ColumnsDirective>
          {memberProjectGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user.id,
    groupId: state.user.id_grupos_usuarios,
    permissionMembers: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 16)
      .map((item) => item.modulosAcciones?.id_acciones),
    permissionUsers: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 16)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(Member);
