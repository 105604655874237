import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { projectGrid } from "../../data/dummy";
import { Header, Backdrop, } from "../../components";
import DeleteUser from "../../components/Modals/projects/DeleteProjects";
import ModalInfoUser from "../../components/Modals/projects/ModalProjects";
import Swal from "sweetalert2";
import { encrypt } from "../../utils/crypt";
import { loginRequest } from "../../actions";

const Projects = (props) => {
  const { token, userId, user, groupId, permission, idProgram, program, loginRequest } =
    props;
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [editId, setEditId] = useState(0);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalRegisterUser, setModalResiterUser] = useState(false);
  const [showModalEditUser, setShowModalEditUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();

  const formatDate = (date) => {
    const newDate = new Date(date /* .replace('T',' ').replace('Z',' ') */);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${newDate.getMonth() + 1 < 10 ? "0" : ""
      }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };

  const exportExcel = async () => {
    const arrayExcel = await users?.map((item) => {
      const user = {
        Nombre: item.nombre,
        "Fecha Inicio": formatDate(item.fecha_inicio),
        "Fecha Fin": formatDate(item.fecha_fin),
        Presupuesto: item.presupuesto,
        Alcance: item.alcance,
        "Entidad Ejecutora": item.entidadEjecutora.razon_social,
      };
      return user;
    });
    await exportToExcel(
      arrayExcel,
      `PROYECTOS ${program}`
    );
  };

  const deleteUsersList = () => {
    if (selectedUsers.length > 0) {
      setShowModalDelete(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un proyecto para poder eliminarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const viewUsers = () => {
    if (selectedUsers.length !== 1) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un proyecto para poder su la información tecnica.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else {
      history(`/proyecto/${encrypt(selectedUsers[0].id)}`);
    }
  };

  const members = () => {
    if (selectedUsers.length !== 1) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un proyecto para poder ver sus miembros.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else {
      history(`/members-projects/${encrypt(selectedUsers[0].id)}`);
    }
  };
  const finances = () => {
    if (selectedUsers.length !== 1) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un proyecto para poder ver sus información financiera.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    } else {
      history(`/finance-proyecto/${encrypt(selectedUsers[0].id)}`);
    }
  };

  const editUsers = () => {
    if (selectedUsers.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un proyecto para poder editarlo.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selectedUsers.length === 1) {
      setEditId(selectedUsers[0].id);
      setShowModalEditUser(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede seleccionar un proyecto para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const registerUser = () => {
    setModalResiterUser(true);
  };

  const deleteUser = {
    align: "Left",
    click: deleteUsersList,
    id: "grid_245085904_1_delete",
    overflow: "None",
    prefixIcon: "e-delete",
    showTextOn: "Both",
    text: "Eliminar",
    tooltipText: "Delete",
    type: "Button",
    visible: permission.includes(4),
    width: "auto",
  };

  const addUser = {
    align: "Left",
    click: registerUser,
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: permission.includes(2),
    width: "auto",
  };

  const EditUser = {
    align: "Left",
    click: editUsers,
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: permission.includes(3),
    width: "auto",
  };

  const member = {
    align: "Left",
    click: members,
    id: "grid_245085904_2_edit",
    prefixIcon: "e-people",
    overflow: "None",
    showTextOn: "Both",
    text: "Miembros",
    tooltipText: "members",
    type: "Button",
    visible: permission.includes(1),
    width: "auto",
  };
  const finance = {
    align: "Left",
    click: finances,
    id: "grid_245085904_2_edit",
    prefixIcon: "e-page-numbering",
    overflow: "None",
    showTextOn: "Both",
    text: "Finanzas",
    tooltipText: "financeReport",
    type: "Button",
    visible: permission.includes(1),
    width: "auto",
  };
  const ViewUser = {
    align: "Left",
    click: viewUsers,
    id: "grid_245085904_2_edit",
    prefixIcon: "e-description",
    overflow: "None",
    showTextOn: "Both",
    text: "Tecnico",
    tooltipText: "technicalReport",
    type: "Button",
    visible: permission.includes(1),
    width: "auto",
  };

  const excelExport = {
    align: "Left",
    click: exportExcel,
    id: "grid_245085904_0_excelexport",
    overflow: "None",
    prefixIcon: "e-excelexport",
    showTextOn: "Both",
    text: "Exportar",
    tooltipText: "ExcelExport",
    type: "Button",
    visible: permission.includes(5),
    width: "auto",
  };

  const toolbarOptions = [
    "Search",
    ViewUser,
    finance,
    member,
    addUser,
    EditUser,
    deleteUser,
    excelExport,
  ];

  const searchOptions = {
    fields: ["nombre", "presupuesto", "entidadEjecutora.razon_social"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  const getUsersProjects = async () => {
    setLoading(true);
    await axios
      .post(
        "/userProject/getuserProjects",
        { id_usuarios: userId },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        let info = [];
        data?.userProjects?.map((item) => {
          info.push(item?.id_proyectos);
        });
        getUsers(info);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        Swal.fire({
          icon: "error",
          text: "Ocurrio un error al consultar los proyectos del usuario.",
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };
  const getUsers = async (info) => {
    setLoading(true);
    await axios
      .post(
        "/project/getProjects",
        { id: info, vp_programa: idProgram },
        {
          headers: {
            "access-token": token,
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        setUsers(data?.projects);
        if (data?.projects.length === 0 && groupId !== 5 && groupId !== 6) {
          if (idProgram === 1) {
            history("/criie-guajira");
          }
          if (idProgram === 2) {
            history("/criie-cordoba");
          }
          if (idProgram === 3) {
            history("/diie-videojuegos");
          }
          if (idProgram === 3) {
            history("/diie-atlantico");
          }
        }
        setEditId(0);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err.response.data.mensaje === "Invalid Token") {
          Swal.fire({
            icon: "error",
            text: "Error al validar usuario.",
            showConfirmButton: false,
            timer: 3000,
          });
          loginRequest();
          history("/");
        } else {
          Swal.fire({
            icon: "error",
            text: "Ocurrio un error al consultar los proyectos.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      if (user.id_grupos_usuarios == 5 || user.id_grupos_usuarios == 6 || user.id_grupos_usuarios == 8) {
        getUsers();
      } else {
        getUsersProjects();
      }
    } else {
      setLoading(false);
      history("/");
    }
  }, [idProgram]);

  const exportToExcel = (jsonData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    if (jsonData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelectedUsers([...selectedUsers, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter(
          (item) => item.id !== data.id
        );
        setSelectedUsers(selectedUsersFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selectedUsers.includes(item.id));
        setSelectedUsers([...selectedUsers, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedUsersFilter = selectedUsers.filter((item) =>
          data.includes(item.id)
        );
        setSelectedUsers(selectedUsersFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <DeleteUser
        show={showModalDelete}
        setShow={setShowModalDelete}
        selected={selectedUsers}
        userId={userId}
        token={token}
        setSelected={setSelectedUsers}
        get={getUsers}
        setLoading={setLoading}
      />
      <ModalInfoUser
        show={showModalRegisterUser}
        setShow={setModalResiterUser}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUsers}
        setLoading={setLoading}
        vp_program={idProgram}
        loginRequest={loginRequest}
      />
      <ModalInfoUser
        show={showModalEditUser}
        setShow={setShowModalEditUser}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUsers}
        editMode
        editId={editId}
        setLoading={setLoading}
        loginRequest={loginRequest}
      />
      <Header
        category="Proyecto"
        title={program}
      />
      <GridComponent
        dataSource={users}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
      >
        <ColumnsDirective>
          {projectGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 3)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

const mapDispatchToprops = {
  loginRequest,
};

export default connect(mapStateToProps, mapDispatchToprops)(Projects);
