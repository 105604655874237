import React, { useState, useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import { userGrid } from "../../data/dummy";
import { Header } from "../../components";
import ModalUserProgram from "../../components/Modals/ModalInfoUserProgram";
import Swal from "sweetalert2";
import { Backdrop } from "../../components"

const UserProgram = (props) => {
  const { token, userId, groupId, permission } = props;
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState(0);
  const [showModalRegister, setModalRegister] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalView, setShowModalView] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useNavigate();


  const view = () => {
    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un Usuario para ver.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selected.length === 1) {
      setData(selected[0]);
      setShowModalView(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede seleccionar un usuario para ver.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const edit = () => {
    if (selected.length === 0) {
      Swal.fire({
        icon: "error",
        text: "debe seleccionar un usuario para poder editar.",
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }
    if (selected.length === 1) {
      setData(selected[0]);
      setShowModalEdit(true);
    } else {
      Swal.fire({
        icon: "error",
        text: "Solo puede seleccionar un usuario para editar.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const register = () => {
    setModalRegister(true);
  };



  const addUserProgram = {
    align: "Left",
    click: register,
    disabled: !permission.includes(2),
    id: "grid_245085904_1_add",
    overflow: "None",
    prefixIcon: "e-add",
    showTextOn: "Both",
    text: "Registrar",
    tooltipText: "Add",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const EditProgram = {
    align: "Left",
    click: edit,
    disabled: !permission.includes(3),
    id: "grid_245085904_2_edit",
    overflow: "None",
    prefixIcon: "e-edit",
    showTextOn: "Both",
    text: "Editar",
    tooltipText: "Edit",
    type: "Button",
    visible: true,
    width: "auto",
  };

  const viewProgram = {
    align: "Left",
    click: view,
    disabled: !permission.includes(1),
    id: "grid_245085904_2_edit",
    prefixIcon: "e-eye",
    overflow: "None",
    showTextOn: "Both",
    text: "Ver",
    tooltipText: "view",
    type: "Button",
    visible: true,
    width: "auto",
  };


  const toolbarOptions = ["Search", addUserProgram, viewProgram, EditProgram];

  const searchOptions = {
    fields: [
      "modulos.nombre",
    ],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };


  const getUsers = async () => {
    setLoading(true)
    await axios.post(
      "/user/getUsers",
      {},
      {
        headers: {
          "access-token": token,
        },
      }
    ).then(({ data }) => {
      setLoading(false)
      setUsers(data?.users);
    }).catch((err) => {
      setLoading(false)
      console.error(err)
      Swal.fire({
        icon: "error",
        text: "Ocurrió un error al consultar los Usuarios.",
        showConfirmButton: false,
        timer: 3000,
      });
    });
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1)) {
      getUsers();
      setLoading(false);
    } else {
      setLoading(false)
      history("/")
    }
  }, []);


  const selectingEvents = (e) => {
    if (!Array.isArray(e.data)) {
      if (e.name === "rowSelecting") {
        setSelected([...selected, e.data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter(
          (item) => item.id !== data.id
        );
        setSelected(selectedFilter);
      }
    } else {
      if (e.name === "rowSelecting") {
        const data = e.data.filter((item) => !selected.includes(item.id));
        setSelected([...selected, ...data]);
      } else if (e.name === "rowDeselected") {
        const { data } = e;
        const selectedFilter = selected.filter((item) =>
          data.includes(item.id)
        );
        setSelected(selectedFilter);
      }
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <ModalUserProgram
        Program
        show={showModalRegister}
        setShow={setModalRegister}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUsers}
        setLoading={setLoading}
      />
      <ModalUserProgram
        Program
        show={showModalEdit}
        setShow={setShowModalEdit}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUsers}
        editMode
        data={data}
        setLoading={setLoading}
      />
      <ModalUserProgram
        Program
        show={showModalView}
        setShow={setShowModalView}
        groupId={groupId}
        token={token}
        userId={userId}
        get={getUsers}
        viewMode
        data={data}
        setLoading={setLoading}
      />
      <Header category="Equipo de Trabajo" title="Usuarios - Programas" />
      <GridComponent
        dataSource={users}
        enableHover={false}
        allowPaging
        selectionSettings={{
          checkboxMode: "ResetOnRowClick",
          type: "Multiple",
        }}
        searchSettings={searchOptions}
        toolbar={toolbarOptions}
        allowSorting
        rowSelecting={selectingEvents}
        cellSelecting={selectingEvents}
        rowDeselected={selectingEvents}
        allowTextWrap
      >
        <ColumnsDirective>
          {userGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
      </GridComponent>
      {
        loading && (
          <Backdrop />
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 21)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};

export default connect(mapStateToProps, null)(UserProgram);
