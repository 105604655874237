import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { scheduleGrid } from "../../../data/dummy";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const ModalSchedule = ({
  show,
  setShow,
  data,
}) => {
    const [activities, setActivities] = React.useState([]);


  const handleClose = () => {
    setShow(false);
  };

  const getActivity = () => {

    const actividades = [];
    if (data !== "") {
      data.map((item) => {
        item?.actividades.map((actividad) => {
          actividades.push(actividad);
        });
      });
      if (actividades.length !== 0) {
        setActivities(actividades);
      }else{
        handleClose();
        Swal.fire({
          icon: "info",
          text: "No hay datos para mostrar",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      
    }
  };

  React.useEffect(() => {
    getActivity();
  }, [show]);

  const toolbarOptions = ["Search"];
  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  return (
    <div>
      <Modal show={show} size="lg">
        <Modal.Header>
          <Modal.Title>
            Cronograma de Actividades
            <box-icon
              name="x"
              onClick={() => handleClose()}
              style={{
                position: "absolute",
                right: "20px",
                cursor: "pointer",
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <GridComponent
            dataSource={activities}
            enableHover={false}
            allowPaging
            pageSettings={{ pageCount: 5 }}
            allowSorting
            toolbar={toolbarOptions}
            searchSettings={searchOptions}
          >
            <ColumnsDirective>
              {scheduleGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
          </GridComponent>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalSchedule;
