import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import Swal from "sweetalert2";
import { Header, Backdrop } from "../../components";
import { encrypt, decrypt } from "../../utils/crypt.js";
import { Tooltip } from "@material-ui/core";
import InfoDeliverable from "../../components/Modals/others/ModalInfoProjects";
import { deleteDeliverable, editActivity } from "../../data/api.js";
import ModalListIndicators from "../../components/Modals/indicators/ModalListIndicators";
import ModalIndicator from "../../components/Modals/indicators/ModalIndicators";
import ModalRange from "../../components/Modals/chart/ModalRange";
import fileDownload from "js-file-download";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Toolbar,
  Edit,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { activitiesGrid } from "../../data/dummy";

const InfoProjects = (props) => {
  const params = useParams();
  const { token, userId, groupId, permission, permissionSupervisor } = props;
  const [project, setProject] = useState([]);
  const [modalAddDeliverable, setModalAddDeliverable] = useState(false);
  const [modalAddIndicator, setModalAddIndicator] = useState(false);
  const [modalEditDeliverable, setModalEditDeliverable] = useState(false);
  const [modalListIndicators, setModalListIndicators] = useState(false);
  const [modalRange, setModalRange] = useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewTable, setViewTable] = useState(false);
  const history = useNavigate();
  const toolbarOptions = ["Search"];
  const searchOptions = {
    fields: ["nombre"],
    ignoreCase: true,
    ignoreAccent: true,
    operator: "contains",
    key: "",
  };

  useEffect(() => {
    setLoading(true);
    if (permission.includes(1) && params.id) {
      getActivity();
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  const formatDate = (date) => {
    const newDate = new Date(date /* .replace('T',' ').replace('Z',' ') */);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${
      newDate.getMonth() + 1 < 10 ? "0" : ""
    }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };

  const getActivity = async () => {
    try {
      const { data } = await axios.get(`/activity/${decrypt(params?.id)}`, {
        headers: { "access-token": token },
      });
      const proyect = data?.activity.entregables.map((entregable) => ({
        ...entregable,
        download: () => download(entregable.ruta),
        upload: () => handelClick("upload", "entregable", entregable),
        addIndicador: () => handelClick("add", "indicador", entregable.id),
        listIndicador: () => handelClick("list", "indicadores", entregable.id),
        editentregable: () => handelClick("edit", "entregable", entregable),
        deleteEntregable: () =>
          handelClick("delete", "entregable", entregable.id),
        permission,
        groupId
      }));
      data.activity.entregables = proyect;
      setProject(data?.activity);
      setLoading(false);
      setViewTable(data?.activity.entregables.length > 0 ? true : false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const approve = () => {
    let text;
    if (project.vp_estado_actividad === 1) {
      text = "¿Desea cambiar el estado a en curso?";
    }
    if (project.vp_estado_actividad === 2) {
      text = "¿Desea solicitar la aprobación de la actividad?";
    }
    if (project.vp_estado_actividad === 3) {
      text = "¿Desea aprobar la actividad?";
    }
    Swal.fire({
      text: text,
      icon: "warning",
      showCancelButton: true,
      showDenyButton: project.vp_estado_actividad === 3 ? true : false,
      confirmButtonColor: "#d3c221",
      cancelButtonColor: "#282828",
      confirmButtonText: "Aceptar",
      denyButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed || result.isDenied) {
        let info;
        if (result.value) {
          info = {
            vp_estado_actividad: project.vp_estado_actividad + 1,
          };
          if (project.vp_estado_actividad + 1 === 4) {
            info = {
              ...info,
              porcentaje: 100,
            };
          }
        } else {
          info = {
            vp_estado_actividad: project.vp_estado_actividad - 1,
          };
        }
        editActivity(project.id, info, token, setLoading, getActivity, null);
      }
    });
  };

  const handelClick = (action, element, info) => {
    setData(info);
    switch (action) {
      case "add":
        switch (element) {
          case "entregable":
            setName("Entregable");
            setModalAddDeliverable(!modalAddDeliverable);
            break;
          case "indicador":
            setModalAddIndicator(true);
            break;
        }
        break;
      case "edit":
        switch (element) {
          case "rango":
            setModalRange(!modalRange);
            break;
          case "entregable":
            setName("Entregable");
            setModalEditDeliverable(!modalEditDeliverable);
            break;
        }
        break;
      case "delete":
        Swal.fire({
          text: `¿Está seguro que desea eliminar este ${element}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d3c221",
          cancelButtonColor: "#282828",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            switch (element) {
              case "entregable":
                deleteDeliverable(info, token, setLoading, getActivity);
                break;
            }
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            Swal.fire({
              text: "Eliminado exitosamente.",
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });

        break;
      case "list":
        switch (element) {
          case "indicadores":
            setModalListIndicators(!modalListIndicators);
            break;
        }
        break;
      case "upload":
        switch (element) {
          case "entregable":
            setName("Entregable");
            setModalEditDeliverable(!modalEditDeliverable);
            break;
        }
        break;
    }
  };

  const download = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.substr(url?.lastIndexOf("=") + 1));
      });
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <InfoDeliverable
        show={modalAddDeliverable}
        setShow={setModalAddDeliverable}
        token={token}
        get={getActivity}
        setLoading={setLoading}
        data={data}
        name={name}
      />
      <InfoDeliverable
        show={modalEditDeliverable}
        setShow={setModalEditDeliverable}
        token={token}
        get={getActivity}
        setLoading={setLoading}
        data={data}
        editMode={true}
        name={name}
      />
      <ModalIndicator
        show={modalAddIndicator}
        setShow={setModalAddIndicator}
        token={token}
        get={getActivity}
        setLoading={setLoading}
        data={data}
      />
      <ModalListIndicators
        show={modalListIndicators}
        setShow={setModalListIndicators}
        token={token}
        setLoading={setLoading}
        data={data}
        permission={permission}
        permissionSupervisor={permissionSupervisor}
      />
      <ModalRange
        show={modalRange}
        setShow={setModalRange}
        token={token}
        get={getActivity}
        setLoading={setLoading}
        data={data}
      />
      <div>
        <Tooltip title="Atras">
          <box-icon
            name="left-arrow-alt"
            onClick={() =>
              history(`/proyecto/${encrypt(project?.objetivos?.id_proyectos)}`)
            }
          />
        </Tooltip>
      </div>
      <Header category={project.objetivos?.nombre} title={project.nombre} />
      <div
        className="icon"
        style={{
          position: "absolute",
          right: "40px",
        }}
      >
        <Tooltip title="Agregar Entregable">
          <box-icon
            name="plus"
            onClick={() => handelClick("add", "entregable", project.id)}
            style={{
              cursor: "pointer",
              display: permission.includes(2) ? null : "none",
            }}
          />
        </Tooltip>
      </div>
      <div className="m-2 p-3 ">
        <p className="h6">
          <span className="fw-bold">Duración de la actividad:</span>{" "}
          {formatDate(project.fecha_inicio)} - {formatDate(project.fecha_fin)}
        </p>
        {/* <p className="h6 fw-bold">Descripción</p>
        <p className="text-justify">
          <em>{project.descripcion_tecnica}</em>
        </p> */}
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: 100,
              height: 100,
              padding: 10,
              margin: 5,
              cursor: project.vp_estado_actividad < 4 ? "pointer" : "default",
            }}
            onClick={
              project.vp_estado_actividad < 4
                ? () => handelClick("edit", "rango", project)
                : null
            }
          >
            <CircularProgressbar
              value={project.porcentaje}
              text={`${project.porcentaje}%`}
              strokeWidth={10}
            />
          </div>
          <div
            className={`p-1 my-5 rounded text-white border${
              project.vp_estado_actividad == 1
                ? " bg-secondary border-secondary"
                : project.vp_estado_actividad > 1 &&
                  project.vp_estado_actividad < 4
                ? " bg-primary border-primary"
                : " bg-success border-success"
            }`}
            onClick={
              permission.includes(2) &&
              permissionSupervisor.includes(2) &&
              project.vp_estado_actividad < 4
                ? () => approve()
                : permissionSupervisor.includes(2) &&
                  project.vp_estado_actividad === 3
                ? () => approve()
                : permission.includes(2) && project.vp_estado_actividad < 3
                ? () => approve()
                : null
            }
            style={{
              cursor:
                permission.includes(2) &&
                permissionSupervisor.includes(2) &&
                project.vp_estado_actividad < 4
                  ? "pointer"
                  : permissionSupervisor.includes(2) &&
                    project.vp_estado_actividad === 3
                  ? "pointer"
                  : permission.includes(2) && project.vp_estado_actividad < 3
                  ? "pointer"
                  : "default",
            }}
          >
            <span className="h6">
              {project.vp_estado_actividad == 1
                ? "Pendiente"
                : project.vp_estado_actividad == 2
                ? "En curso"
                : project.vp_estado_actividad == 3
                ? "¿Aprobar?"
                : "Finalizado"}
            </span>
          </div>
        </div>
      </div>

      {viewTable && (
        <>
          <p className="h4 fw-bold">Entregables</p>
          <GridComponent
            dataSource={project.entregables}
            enableHover={false}
            allowPaging
            searchSettings={searchOptions}
            toolbar={toolbarOptions}
            pageSettings={{ pageCount: 5 }}
            allowSorting
          >
            <ColumnsDirective>
              {activitiesGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
          </GridComponent>
        </>
      )}
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permission: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 4)
      .map((item) => item.modulosAcciones?.id_acciones),
    permissionSupervisor: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 5)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};
export default connect(mapStateToProps, null)(InfoProjects);
