import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest, setPermissions, setToken } from "../../actions";
import {
  makeStyles,
  Grid,
  Button,
  CssBaseline,
  Paper,
  Typography,
  Link,
} from "@material-ui/core";
import axios from "../../api";
import Swal from "sweetalert2";
import HomeBackground from "../../assets/fondo.jpg";
import Backdrop from "../../components/Backdrop";
import Logo from "../../assets/LogoProjectPlace.png";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { ModalForgetPassword } from "../../components/Modals"

function SignIn(props) {
  const { user, loginRequest, setPermissions, setToken } = props;
  const history = useNavigate();
  const classes = useStyles();
  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

/*   useEffect(async() => {
    const _user = await getUser()
    if (_user && Object.keys(user || {}).length > 0) {
      history("/diie-atlantico");
    } 
    // else {
    //   history("/not-fount");
    //   window.location.reload();
    // }
  }, []); */

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validate = e.currentTarget;
    if (validate.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      setLoading(true);
      axios
        .post(`/user/signin`, form)
        .then((res) => {
          if (res.data.user.success) {
            loginRequest(res.data?.user?.user);
            setPermissions(res.data?.user?.permissions);
            setToken(res.data?.user?.token);
            if (
              res.data?.user?.user?.id_grupos_usuarios === 1 ||
              res.data?.user?.user?.id_grupos_usuarios === 2
            ) {
              history(res.data?.user?.usuariosProgramas?.programas[0].url);
            } else {
              if(res.data?.user?.user?.usuariosProgramas?.length===0){
                if(res.data?.user?.user?.id_grupos_usuarios === 6 || res.data?.user?.user?.id_grupos_usuarios === 8){
                  history("/program")
                }
                else{
                  Swal.fire({
                    icon: "error",
                    text: "No se le ha asignado programa comunicarse con el personal encargado",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                }
              }else{
                history(`/${res.data?.user?.user?.usuariosProgramas[0].programas.url}`);
                window.location.reload();
              }
            }
            setLoading(false);
          } else {
            setLoading(false);
            Swal.fire({
              icon: "error",
              text: res.data.user.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error)
          Swal.fire({
            icon: "error",
            text: "Error al iniciar sesión.",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    }

  };

  const getUser = async () => {
    try {
      const data = axios.post(`/user/getUsers`, {
        id: user.id,
        estado: 1,
      },
        {
          headers: {
            "access-token": token,
          },
        }).then(({ data }) => {
          if (data.users[0].id === user.id) {
            history("/diie-atlantico");
          }
        }).catch((error) => {
          console.error(error);
          if (error.mensaje === "Invalid Token") {
            Swal.fire({
              icon: "error",
              text: "Error al validar usuario.",
              showConfirmButton: false,
              timer: 3000,
            });
            history("/");
          }
          loginRequest();
        });
      return data.user;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <ModalForgetPassword
        show={showModal}
        setShow={setShowModal}
        setToken={setToken}
        setLoading={setLoading}
      />
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image}>
      </Grid>
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.containerLogo}>
            <img alt="logo" src={Logo} width={270} height={270} />
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h4"
                style={{ fontWeight: "bolder", letterSpacing: "-1px", color: "#032f3c" }}
                className="text-center"
              >
                Acceder
              </Typography>
            </Grid>
          </Grid>
          <Form className={classes.form} onSubmit={handleSubmit} noValidate validated={validated}>
            <Row className="mb-2">
              <Form.Group as={Col} md="12" controlId="validationDate">
                <FloatingLabel controlId="email" label="Correo Electronico *" className="mb">
                  <Form.Control
                    type="email"
                    placeholder="Correo Electronico *"
                    name="email"
                    autoComplete="email"
                    required
                    variant="outlined"
                    onChange={handleInput}
                  />
                  <Form.Control.Feedback type="invalid">
                    El corre electronico es obligatorio.
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} md="12" controlId="validationDate">
                <FloatingLabel controlId="password" label="Contraseña *" className="mb">
                  <Form.Control
                    type="password"
                    placeholder="Contraseña *"
                    name="password"
                    autoComplete="current-password"
                    required
                    variant="outlined"
                    onChange={handleInput}
                  />
                  <Form.Control.Feedback type="invalid">
                    La contraseña es obligatoria
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="justify-content-center mb-2">
              <Button
                color="primary"
                type="submit"
                variant="contained"
                className={classes.submit}
              >
                Iniciar sesión
              </Button>
            </Row>
            <Grid container>
              <Grid item xs className="text-center">
                <Link
                  href="#"
                  variant="body2"
                  // onClick={() => history("/account/forget")}
                  onClick={() => setShowModal(true)}
                  color="primary"
                  style={{ fontWeight: "bolder", "color": "#032f3c" }}
                >
                  ¿Olvidaste la contraseña?
                </Link>
              </Grid>
            </Grid>
          </Form>
        </div>
      </Grid>
      {
        loading && (
          <Backdrop />
        )
      }

    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${HomeBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(16, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(17, 6),
    },
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    width: "13em",
    fontWeight: "bolder",
    borderRadius: "10px",
    "background-color": "#032f3c",
    "&:hover": {
      background: "#1b4a61"
    },
  },
  container__input_root: {
    borderRadius: "10px",
  },
  containerLogo: {
    margin: "-5em 0 3em 0",
  },
  textField: {
    margin: "11em 1em 0 1em",
    color: theme.palette.background.main,
    fontWeight: "bolder",
    fontSize: "42px",
    textShadow: "2px 2px 2px black",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
}));

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToprops = {
  loginRequest,
  setPermissions,
  setToken,
};

export default connect(mapStateToProps, mapDispatchToprops)(SignIn);
