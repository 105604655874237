import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "../../api";
import Swal from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
import { Header, Backdrop } from "../../components";
import { encrypt, decrypt } from "../../utils/crypt.js";
import { Tooltip } from "@material-ui/core";
import InfoObjective from "../../components/Modals/others/ModalInfoProjects";
import ModalActivity from "../../components/Modals/others/ModalActivity";
import {
  deleteObjective,
  deleteActivity,
  deleteDeliverable,
} from "../../data/api.js";
import ModalListIndicators from "../../components/Modals/indicators/ModalListIndicators";
import ModalIndicator from "../../components/Modals/indicators/ModalIndicators";
import ModalExpense from "../../components/Modals/expenses/ModalExpense";
import ModalActivitiesChart from "../../components/Modals/chart/ModalActivitiesChart";
import ModalListDocuments from "../../components/Modals/documents/ModalListDocuments";
import ModalDocumentos from "../../components/Modals/documents/ModalDocuments";
import ModalSchedule from "../../components/Modals/schedule/ModalSchedule";
import iconObjetivo from "../../assets/icon/Check.png";
import iconActividad from "../../assets/icon/Actividad.png";
import iconAddInforme from "../../assets/icon/agregar_informe.png";
import iconAddDocumento from "../../assets/icon/agregar_tarea.png";
import iconGrafica from "../../assets/icon/graficas.png";
import iconAddObjetivo from "../../assets/icon/objetivo.png";
import iconViewActivity from "../../assets/icon/cronograma.png";
import iconViewInforme from "../../assets/icon/visualizar_informe.png";
import iconViewDocumento from "../../assets/icon/visualizar_tarea.png";

const InfoProjects = (props) => {
  const params = useParams();
  const {
    token,
    userId,
    groupId,
    permissionDocuments,
    permissionDocumentsSupervisor,
  } = props;
  const [project, setProject] = useState([]);
  const [modalAddObjective, setModalAddObjective] = useState(false);
  const [modalAddActivity, setModalAddActivity] = useState(false);
  const [modalAddIndicator, setModalAddIndicator] = useState(false);
  const [modalAddExpense, setModalAddExpense] = useState(false);
  const [modalEditActivity, setModalEditActivity] = useState(false);
  const [modalEditObjective, setModalEditObjective] = useState(false);
  const [modalListIndicators, setModalListIndicators] = useState(false);
  const [modalActivitiesChart, setModalActivitiesChart] = useState(false);
  const [modalShedule, setModalSchedule] = useState(false);
  const [modalDocumentos, setModalDocumentos] = useState(false);
  const [modalAddDocument, setModalAddDocument] = React.useState(false);
  const [supervisor, setSupervisor] = React.useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    if (permissionDocuments.includes(1) && params.id) {
      getProject();
    } else {
      setLoading(false);
      history("/");
    }
  }, []);

  const formatDate = (date) => {
    const newDate = new Date(date);
    return `${newDate.getDate() < 10 ? "0" : ""}${newDate.getDate()}/${
      newDate.getMonth() + 1 < 10 ? "0" : ""
    }${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
  };

  const getProject = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/project/${decrypt(params?.id)}`, {
        headers: { "access-token": token },
      });
      setProject(data.project);
      setLoading(false);
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Error al Consultar los datos del Usuario",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const formatterPeso = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  });
  const handelClick = (action, element, info) => {
    setData(info);
    switch (action) {
      case "add":
        switch (element) {
          case "objetivo":
            setName("Objetivo");
            setModalAddObjective(!modalAddObjective);
            break;
          case "actividad":
            setModalAddActivity(!modalAddActivity);
            break;
          case "entregable":
            setName("Entregable");
            setModalAddObjective(!modalAddObjective);
            break;
          case "indicador":
            setModalAddIndicator(!modalAddIndicator);
            break;
          case "gasto":
            setModalAddExpense(!modalAddExpense);
            break;
          case "documentos":
            setModalAddDocument(!modalAddDocument);
            break;
          case "informe":
            setSupervisor(!supervisor);
            setModalAddDocument(!modalAddDocument);
            break;
        }
        break;
      case "edit":
        switch (element) {
          case "objetivo":
            setName("Objetivo");
            setModalEditObjective(!modalEditObjective);
            break;
          case "actividad":
            setModalEditActivity(!modalEditActivity);
            break;
          case "entregable":
            setName("Entregable");
            setModalEditObjective(!modalEditObjective);
            break;
        }
        break;
      case "delete":
        Swal.fire({
          text: `¿Está seguro que desea eliminar este ${element}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d3c221",
          cancelButtonColor: "#282828",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            switch (element) {
              case "objetivo":
                deleteObjective(info, token, setLoading, getProject);
                break;
              case "actividad":
                deleteActivity(info, token, setLoading, getProject);
                break;
              case "entregable":
                deleteDeliverable(info, token, setLoading, getProject);
                break;
            }
          }
        });
        break;
      case "view":
        switch (element) {
          case "graficaActividades":
            setModalActivitiesChart(!modalActivitiesChart);
            break;
          case "cronograma":
            setModalSchedule(!modalShedule);
            break;
          case "documentos":
            setModalDocumentos(!modalDocumentos);
            break;
          case "supervisor":
            setSupervisor(!supervisor);
            setModalDocumentos(!modalDocumentos);
            break;
        }
        break;
    }
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <InfoObjective
        show={modalAddObjective}
        setShow={setModalAddObjective}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        name={name}
      />
      <InfoObjective
        show={modalEditObjective}
        setShow={setModalEditObjective}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        editMode={true}
        name={name}
      />
      <ModalIndicator
        show={modalAddIndicator}
        setShow={setModalAddIndicator}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
      />
      <ModalActivity
        show={modalAddActivity}
        setShow={setModalAddActivity}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        min={project?.fecha_inicio}
        max={project?.fecha_fin}
      />
      <ModalActivity
        show={modalEditActivity}
        setShow={setModalEditActivity}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        editMode={true}
        min={project?.fecha_inicio}
        max={project?.fecha_fin}
      />
      <ModalActivitiesChart
        show={modalActivitiesChart}
        setShow={setModalActivitiesChart}
        setLoading={setLoading}
        data={data}
      />
      <ModalSchedule
        show={modalShedule}
        setShow={setModalSchedule}
        setLoading={setLoading}
        data={data}
      />
      <ModalListDocuments
        show={modalDocumentos}
        setShow={setModalDocumentos}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        supervisor={supervisor}
        setSupervisor={setSupervisor}
        documentTecnico={true}
        permission={
          supervisor ? permissionDocumentsSupervisor : permissionDocuments
        }
      />
      <ModalDocumentos
        show={modalAddDocument}
        setShow={setModalAddDocument}
        token={token}
        get={getProject}
        setLoading={setLoading}
        data={data}
        supervisor={supervisor}
        setSupervisor={setSupervisor}
        documentTecnico={true}
      />
      <Tooltip title="Atras">
        <box-icon
          name="left-arrow-alt"
          onClick={() =>
            project.vp_programa == 1
              ? history(`/diie-atlantico`)
              : project.vp_programa == 2
              ? history(`/criie-guajira`):
                project.vp_programa==3?
                 history(`/criie-cordoba`)
                 : history(`/diie-videojuegos`)
          }
        />
      </Tooltip>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Tooltip title="Agregar Informe Supervision">
          <div
            onClick={() => handelClick("add", "informe", project.id)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocumentsSupervisor.includes(2)
                ? null
                : "none",
            }}
          >
            <img
              src={iconAddInforme}
              alt="Añadir Informe"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Agregar Documento">
          <div
            onClick={() => handelClick("add", "documentos", project.id)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocuments.includes(2) ? null : "none",
            }}
          >
            <img
              src={iconAddDocumento}
              alt="Añadir Documento"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Informes Técnicos">
          <div
            onClick={() => handelClick("view", "supervisor", project.id)}
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img
              src={iconViewInforme}
              alt="Ver Informe"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Documentos">
          <div
            onClick={() => handelClick("view", "documentos", project.id)}
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img
              src={iconViewDocumento}
              alt="Ver Informe Tecnicos"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Ver Grafica Actividades">
          <div
            onClick={() => handelClick("view", "graficaActividades", project)}
            style={{ cursor: "pointer", marginRight: 10 }}
          >
            <img src={iconGrafica} alt="Ver Grafica" style={{ width: 50 }} />
          </div>
        </Tooltip>
        <Tooltip title="Agregar Objetivo">
          <div
            onClick={() => handelClick("add", "objetivo", project.id)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocuments.includes(2) ? null : "none",
            }}
          >
            <img
              src={iconAddObjetivo}
              alt="Agregar Objetivo"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Cronograma de actividades">
          <div
            onClick={() => handelClick("view", "cronograma", project.objetivos)}
            style={{
              cursor: "pointer",
              marginRight: 10,
              display: permissionDocuments.includes(1) ? null : "none",
            }}
          >
            <img
              src={iconViewActivity}
              alt="Cronograma de Actividades"
              style={{ width: 50 }}
            />
          </div>
        </Tooltip>
      </div>
      <Header
        category={
          project.vp_programa == 1
            ? `Diie Atlantico - ${project?.entidadEjecutora?.razon_social}`
            : project.vp_programa == 2
            ? `Criie Guajira - ${project?.entidadEjecutora?.razon_social}`
            : `Criie Cordoba - ${project?.entidadEjecutora?.razon_social}`
        }
        title={project.nombre}
      />
      <p className="h6">
        <span className="fw-bold">Duración del proyecto:</span>{" "}
        {formatDate(project.fecha_inicio)} - {formatDate(project.fecha_fin)}
      </p>
      {/* <p className="h6">
        <span className="fw-bold">Presupuesto: </span>{" "}
        {formatterPeso.format(project.presupuesto)}
      </p> */}
      <p className="h6 fw-bold">Alcance</p>
      <p className="text-justify pb-2">
        <em>{project.alcance}</em>
      </p>
      <p className="h6 fw-bold ">Objetivo General</p>
      <p className="text-justify">
        <em>{project.objetivo_general}</em>
      </p>
      <br />
      <Accordion>
        {project?.objetivos?.map((row) => (
          <Accordion.Item
            key={`objective${row.id}`}
            eventKey={`objective${row.id}`}
          >
            <Accordion.Header>
              <div style={{ width: 35, marginRight: 10 }}>
                <img src={iconObjetivo} alt="Objetivo" />
              </div>
              {row.nombre}
            </Accordion.Header>
            <Accordion.Body>
              <div
                className="icon"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Tooltip title="Agregar Actividades">
                  <box-icon
                    name="plus"
                    onClick={() => handelClick("add", "actividad", row.id)}
                    style={{
                      cursor: "pointer",
                      display: permissionDocuments.includes(2) ? null : "none",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Editar Objetivo">
                  <box-icon
                    name="edit"
                    onClick={() => handelClick("edit", "objetivo", row)}
                    style={{
                      cursor: "pointer",
                      display: permissionDocuments.includes(3) ? null : "none",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Eliminar Objetivo">
                  <box-icon
                    name="x"
                    onClick={() => handelClick("delete", "objetivo", row.id)}
                    style={{
                      cursor: "pointer",
                      display: permissionDocuments.includes(4) ? null : "none",
                    }}
                  />
                </Tooltip>
              </div>
              {/* <div className="m-2 p-3 ">
                <p className="text-justify">
                  <em>{row.descripcion}</em>
                </p>
              </div> */}
              {row?.actividades?.map((row) => (
                <div className="rounded border" style={{ height: 85 }}>
                  <div
                    style={{
                      marginTop: 23,
                      marginLeft: 13,
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={iconActividad}
                      alt="Objetivo"
                      style={{ width: 35, marginRight: 10 }}
                    />
                    <span style={{width:'80%'}}>{row.nombre}</span>
                    <div style={{ position: "absolute", right: "60px" }}>
                      <Tooltip title="Editar Actividad">
                        <box-icon
                          name="edit"
                          onClick={() => handelClick("edit", "actividad", row)}
                          style={{
                            cursor: "pointer",
                            display: permissionDocuments.includes(3)
                              ? null
                              : "none",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Eliminar Actividad">
                        <box-icon
                          name="x"
                          onClick={() =>
                            handelClick("delete", "actividad", row.id)
                          }
                          style={{
                            cursor: "pointer",
                            display: permissionDocuments.includes(4)
                              ? null
                              : "none",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Ver Actividad">
                        <box-icon
                          name="chevron-right"
                          onClick={() =>
                            history(`/actividad/${encrypt(row.id)}`)
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {loading && <Backdrop />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.token,
    userId: state.user?.id,
    groupId: state.user?.id_grupos_usuarios,
    permissionDocuments: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 4)
      .map((item) => item.modulosAcciones?.id_acciones),
    permissionDocumentsSupervisor: (state.permission || [])
      .filter((data) => data.modulosAcciones?.id_modulos === 5)
      .map((item) => item.modulosAcciones?.id_acciones),
  };
};
export default connect(mapStateToProps, null)(InfoProjects);
